import { useApi } from "@/composition/useApi.js"
export default{
    state: ()=>({
        getRates: [],
        textError: null,
    }),
    mutations:{
        clearAllRates(state){
            state.getRates = []
        },
        changeRates(state, data){
            state.getRates.forEach(rate =>{
                if(rate.id == data.idRateOld){
                    rate.price = data.rateNew.price
                }
            })
        },
        setUpdateRates(state, data){
            state.getRates = data
        },
        setAddedRates(state,rate){
            state.getRates.push(rate) 
        },
        deleteRates(state,rate){
            state.getRates = state.getRates.filter(item=> item.price != rate.price)     
        },
        errorTextChange(state, text){
            state.textError = text
            setTimeout(()=>{state.textError = null}, 3500)
        }
    },
    actions:{
        addedRates({commit},body){
            const { api } = useApi();
            return api.post(`/rates`, body)
                .then((r) => {
                    if(r.status == 201)
                        commit('setAddedRates',r.data)
                    return r.status
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('errorTextChange', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        deleteRates({commit},rate){
            const { api } = useApi();
            return api.delete(`/rates/${rate.id}`)
                .then((r) => {
                    if(r.status == 204)
                        commit('deleteRates',rate)  
                })
                .catch((error) => {
                    if(error.response.status === 400)
                        commit('errorTextChange', "Нельзя удалить тариф пока он привязан к одному из счетов")

                    if(error.response.status === 500)
                        commit('errorTextChange', "Ошибка ответа от сервера(500)")
                })
        },
        changeRate({commit},data){
            const { api } = useApi();
            return api.patch(`/rates/${data.idRateOld}`, data.rateNew)
                .then((r) => {
                    if(r.status == 204){
                        commit('changeRates',data) 
                        return r.status
                    }           
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('errorTextChange', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        }

    }
}