<template>
    <div class="billing">
      <div class="billing__tubs" v-if="activeTabs && activeTabs.length">
        <TubsComponent :data="activeTabs" @allInvoiced = 'allInvoicedAndEdit'/> 
      </div>
      <div class="billing__inner">
        <div class="billing__button">
          <div class="billing__button-rate"  v-if="selectedTab && (selectedTab.tab !== 'Отчет списания')">
            <ButtonComponent @click="openRates">Тарифы</ButtonComponent>
          </div>
          <div class="billing__button-group" v-if="selectedTab && (selectedTab.tab !== 'Отчет списания')">
            <ButtonComponent v-if="(selectedTab && selectedTab.tab == 'Все счета')"
            @click="createInvoice">Создать</ButtonComponent>
            <ButtonComponent v-else
            @click="canselInvoice">Отмена</ButtonComponent>
          </div>
        </div>
        <div class="billing__page" v-if="(selectedTab && (selectedTab.tab !== 'Отчет списания'))">
          <InvoiceComponent :selectedTab="selectedTab"
            @editInvoice = 'allInvoicedAndEdit' 
            @redirectCreate="allInvoicedAndEdit" />
        </div>
        <div class="billing__page" v-else>
          <WriteOffComponent :selectedTab="selectedTab"  v-if="$store.state.billing.isVisibleInvoices" />
          <div class="billing__page-noCompanies" v-else>
              Счета отсутствуют
            </div> 
        </div>
      </div>
      <div class="billing__modal" v-if="isModalMainRates">
        <ModalDefaultStyles :width = 650>
          <ModalMainRatesBilling @closeRates="closeRates" />
        </ModalDefaultStyles> 
      </div>
    </div>
  </template>
  
  <script>
  import ButtonComponent from "@/components/UI/ButtonComponent.vue";
  import TubsComponent from '@/components/UI/TubsComponent.vue';
  import InvoiceComponent from '@/components/billingComponents/InvoiceComponent.vue';
  import ModalDefaultStyles from "@/components/UI/ModalDefaultStyles.vue";
  import ModalMainRatesBilling from "@/components/modalComponents/billing/ModalMainRatesBilling.vue";
  import WriteOffComponent from '@/components/billingComponents/WriteOffComponent.vue';
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router';
  import { ref, onMounted } from 'vue'
  export default {
    name: 'BillingPage',
    components: {
      ButtonComponent,
      TubsComponent,
      InvoiceComponent,
      ModalDefaultStyles,
      ModalMainRatesBilling,
      WriteOffComponent
    },
    setup(){
      const store = useStore()
      const router = useRouter()

      const activeTabs = ref(null)
      const selectedTab = ref({})

      const allInvoicedAndEdit = (tab)=>{
        store.commit('setBillingTabs',tab)
        selectedTab.value = tab
      }
      const createInvoice = ()=>{
        store.commit('setBillingTabs',store.state.billing.billingTabs[1])
        selectedTab.value = store.state.billing.billingTabs[1]
      }

      const canselInvoice = ()=>{
        store.commit('setBillingTabs',store.state.billing.billingTabs[0])
        selectedTab.value = store.state.billing.billingTabs[0]

      }
      //модальное окно
      const isModalMainRates = ref(false)
      const openRates = ()=>{
        isModalMainRates.value = true
      }
      const closeRates = (bool)=>{
        isModalMainRates.value = bool
      }
      onMounted(()=>{
        // визуальное отображение Табов
        activeTabs.value = [store.state.billing.billingTabs[0],store.state.billing.billingTabs[3]] 
        // визуальное отображение страниц
        selectedTab.value = store.state.billing.billingTabs[0]
        store.commit('setBillingTabs',store.state.billing.billingTabs[0])
        // название Title у Header
        store.commit('setTitleForHeader',router.currentRoute.value.meta.nameTitle)
      })
      return{
        activeTabs,
        selectedTab,
  
        allInvoicedAndEdit,
        canselInvoice,
        createInvoice,

        isModalMainRates,
        openRates,
        closeRates,
      }
    }
  }
  </script>
  <style lang="scss" src="@/assets/styles/billing/billingPage.scss"></style>