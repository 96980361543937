<template>
    <div class="modalMainRates">
        <div class="modalMainRates__button">
            <ButtonComponent @click="closeRates">Закрыть окно</ButtonComponent>
        </div>
        <div class="modalMainRates__rates">
            <div class="modalMainRates__rates-title">ТАРИФЫ</div>
            <div class="modalMainRates__rates-info">
                <table class="modalMainRates__rates-table" v-if="allRates && allRates.length">
                    <thead class="modalMainRates__rates-thead">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Тариф</th>
                            <th scope="col">действие</th>
                        </tr>
                    </thead>
                    <tbody class="modalMainRates__rates-tbody">
                        <tr class=""  v-for="(rate,idx) in allRates" :key="rate.id">
                            <td class="modalMainRates__rates-id">{{idx+1}}</td>
                            <td class="modalMainRates__rates-name">
                                <div v-if="!rate.isVisibleChange" >{{rate.price}}</div>        
                                <input class="modalMainRates__rates-name--change" type="number" placeholder="подтвердите, нажав 'Enter'"  v-else
                                    @keyup.enter="changeRate(rate)"
                                    :value="dataChangeRate" @input="dataChangeRate = $event.target.value">
                            </td>
                            <td class="modalMainRates__rates-buttons">
                                <div class="modalMainRates__rates-action">
                                    <EditButtonComponent :edit="true" @click="rateEdit(rate)" />
                                    <EditButtonComponent :edit="false" @click.stop="rateDelete(rate)" />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modalMainRates__added">
            <div class="modalMainRates__added-info">
                Добавить тариф
            </div>
            <div class="modalMainRates__added-wrap">
                <div class="modalMainRates__added-button" @click.stop="addedRate">
                    <button>Добавить</button>
                </div>                
                <div class="modalMainRates__added-input">
                    <input type="number" placeholder="Тариф" :value="inputRateNumber" @input="inputRateNumber = $event.target.value">
                </div>
            </div>
        </div>
        <ModalError :infoText="$store.state.rates.textError" v-if="$store.state.rates.textError"/>
    </div>
</template>
<script>
import ModalError from "@/components/modalComponents/ModalError.vue";
import ButtonComponent from "@/components/UI/ButtonComponent.vue";
import EditButtonComponent from "@/components/UI/EditButtonComponent.vue";
import { ref, onMounted, onUnmounted } from "vue";
import { useStore } from 'vuex';
export default {
    name: 'modalMainRates',
    components: {
        ModalError,
        ButtonComponent,
        EditButtonComponent,
    },
    setup(props, {emit}){
        const store = useStore()

        const closeRates = ()=>{
            emit('closeRates', false)
        }
        // изменение тарифа
        const dataChangeRate = ref('')
        const rateEdit = (rate)=>{
            dataChangeRate.value = ''
            rate.isVisibleChange = !rate.isVisibleChange
        }
        const changeRate = (rate)=>{
            let data = {
                idRateOld: rate.id,
                rateNew: {price: parseInt(dataChangeRate.value)}
            }            
            if(confirm(`Изменить тариф на ${dataChangeRate.value}?`)){
                store.dispatch('changeRate',data).then((status)=>{
                    if(status == 204){
                        rate.isVisibleChange = false
                        allRates.value = store.state.rates.getRates                        
                    }
                })
            }else{
                rate.isVisibleChange = false
            }
        }
        // удаление тарифа
        const rateDelete = (rate)=>{
            if(confirm(`Удалить тариф ${rate.price}?`))
                store.dispatch('deleteRates',rate).then(()=> allRates.value = store.state.rates.getRates )
        }

        const inputRateNumber = ref('')
        const allRates = ref(null)

        const addedRate = ()=>{
            if(allRates.value && allRates.value.some( rate => rate.price == inputRateNumber.value) && inputRateNumber.value.length)
                return
            else{
                if(inputRateNumber.value){
                    store.dispatch('addedRates',{"price": parseInt(inputRateNumber.value)}).then((status)=>{
                        if(status == 201)
                            allRates.value = store.state.rates.getRates                            
                    })                      
                }
            }
            
            // очищение
            inputRateNumber.value = ''
        }

        onMounted(()=>{
            // обновление данных, при первой загрузке окна
            store.commit('setUpdateRates', store.state.billing.allRates.value)
            allRates.value = store.state.billing.allRates.value
        })

        onUnmounted(()=>{
            store.dispatch('getAllRates') 
        })

        return{
            allRates,

            closeRates,

            rateDelete,
            inputRateNumber,
            addedRate,
            
            dataChangeRate,
            rateEdit,
            changeRate,
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.modalMainRates{
    position: relative;
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    padding: 5px 10px;
    &__button{
        text-align: end;
    }
    &__rates{
        margin-bottom: 15px;
        &-title{
            @include fontStyleTitle;
            margin-top: 15px;
            text-align: center;
        }
        &-table{
            width: 100%;
        }
        &-action{
            width: 82px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
        } 
        &-id{
            width: 20%;
        }
        &-name{
            width: 60%;
            &--change{
                width: 40%;
                min-width: 250px;
                text-align: center;
                margin: 0;
                padding: 0.4rem 0.75rem;
                background-color: #fff;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                outline: none;
            }
        }
    }
    &__rates-table{
        width: 100%;
        border-collapse: collapse;
        & tr{
            border-bottom: 1px solid #dee2e6;
        }
        & th, & td{
            padding: 0.5rem 0.5rem;
            text-align: center;
        }
    }
    &__added{
        margin-bottom: 30px;
        padding: 12px 5px;
        width: 100%;
        transition: all .3s ease-in-out;
        &:hover{
            background-color: $rowactive;
        }
        &-wrap{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 39px;
        }
        &-info{
            padding: 6px 12px;
            margin-bottom: 6px;
            text-align: center;
        }
        &-input{
            flex: 1 0 auto;
            height: 100%;
        }
        &-input input{
            width: 100%;
            height: 100%;
            padding: 0.375rem 0.75rem;
            background-color: #fff;
            border: 1px solid #ced4da;
            border-top-right-radius:  0.25rem;
            border-bottom-right-radius:  0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            outline: none;
            &:focus{
                border-color: $buttonColor;
                box-shadow: 2.5px 0 0 0.16rem rgb(227 30 36 / 25%);
            }
        }
        &-button{
            height: 100%;
        }
        &-button button{
            color: #fff;
            background-color: $buttonColor;
            border-color: $buttonColor;
            border: 1px solid transparent;
            padding: 9px 12px;
            text-align: center;
            border-top-left-radius:  0.25rem;
            border-bottom-left-radius:  0.25rem;
            cursor: pointer;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            &:hover{
                color: #fff;
                background-color: $buttonHover;
                border-color: $buttonHover;
                box-shadow: -2.5px 0 0 0.16rem rgb(227 30 36 / 25%);
            }
        }

    }
    & .modalError{
        text-align: center;
    }
}
</style>