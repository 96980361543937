import { useApi } from "@/composition/useApi.js"
export default{
    state: ()=>({
        totalFooterMINUS: [],
        totalFooterPLUS : null,
        totalFooter: [],

        isInfoEmpty: false,
        isSpinerReport: false,

        infoEmptyInvoices: null,
        isVisibleErrorModal: false,

        isVisibleTable: false,
        dataInvoicesReport: [],
        timeInterval: [
            {
                name: 'Сегодня'
            },
            {
                name: 'Вчера'
            },
            {
                name: 'Текущая неделя'
            },
            {
                name: 'Предыдущая неделя'
            },
            {
                name: 'Текущий месяц'
            },
            {
                name: 'Предыдущий месяц'
            },
            {
                name: 'На выбор'
            },
        ],
    }),
    mutations:{
        setTotalFooterMINUS(state, data){
            state.totalFooterMINUS.push(data)
        },
        setTotalFooter(state){
            state.totalFooter = String(state.totalFooterMINUS.reduce((sum, el) => sum + parseFloat(el),0).toFixed(2))
        },
        setTotalPLUS(state, data){
            state.totalFooterPLUS = data
        },
        clearTotalFooter(state){
            state.totalFooterMINUS = []
            state.totalFooter = ''
        },
        setVisibleTable(state, bool){
            state.isVisibleTable = bool
        },
        setDataInvoicesReport(state, data){
            state.dataInvoicesReport = data
        },

        setInfoEmpty(state, bool){
            state.isInfoEmpty = bool
        },
        setSpinerReport(state, bool){
            state.isSpinerReport = bool
        },

        setInfoEmptyInvoices(state, text){
            state.infoEmptyInvoices = text
        },
        setVisibleErrorModal(state,bool){
            state.isVisibleErrorModal = bool
        }

    },
    actions:{
        getInvoiceReport({commit},data){
            data.ids = data.ids.join()
            const { api } = useApi();
            return api.get(`/invoices/balance/report?ids=${data.ids}&from=${data.timestart}&to=${data.timeend}`)
                .then((r) => {
                    if(r.status == 200){
                        if(r.data.header && r.data.header.length)
                            commit('setDataInvoicesReport',r.data)   
                        else
                            commit('setInfoEmpty', true)                           
                    }
                })
        },


    }
}