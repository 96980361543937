import { useApi } from "@/composition/useApi.js"
import { searchTerminalsFuncions } from "@/composition/searchAllTerminals.js"
export default{
    state: ()=>({
        allFoldersGeozones: [],
        isSpinerMainFolders: true,
        errorTextRequest: null
    }),
    mutations:{
        // ГЛАВНАЯ СТРАНИЦА
        setAllFoldersGeozones(state, data){
            state.allFoldersGeozones = data
            // доп обработка для работы поиска (вводит доп поля для открисовки папок)
            if(state.allFoldersGeozones){
                // получение данных из файла "поиски для терминалов"
                const { visibleAllFolders } = searchTerminalsFuncions()
                // закрытие всех папок ( закрытие всех внутренних папок , отрисовка всех папок(отрисовывваем все) )
                visibleAllFolders(state.allFoldersGeozones, false, true)
            }
        },
        // АКТИВНОСТЬ СПИННЕРОВ НА ГЛАВНОЙ СТРАНИЦЕ
        setGeozonesFoldersSpinner(state, bool){
            state.isSpinerMainFolders = bool
        },
        // ErrorText
        setErrorTextRequest(state, text){
            state.errorTextRequest = text
            setTimeout(()=> state.errorTextRequest = null, 3000)
        },
    },
    actions:{
        getGeozonesByFolderId(ctx, folderId){
            const { api } = useApi();
            return api.get(`/geozones/folder/${folderId}`)
        },
        deleteGeozone(ctx, id){
            const { api } = useApi();
            return api.delete(`/geozones/${id}`)
        },
        editGeozone({commit}, data){
            const { api } = useApi();
            return api.put(`/geozones/${data.id}`, data.data)
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        createGeozone({commit}, data){
            const { api } = useApi();
            return api.post(`/geozones`, data)
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
    }
}
