<template>
  <div class="wrapper" @click="clearEvents">
    <div class="wrapper__sidebar" v-if="$store.state.useApi.userInfo?.login">
        <MainSidebar/>
      </div>
    <div class="wrapper__main" v-if="$store.state.useApi.userInfo?.login">
      <div class="wrapper__header">
        <MainHeader/>
      </div>
      <div class="wrapper__page">
        <router-view/>
      </div>
    </div>
  </div>
</template>
<script>

import MainSidebar from '@/components/MainSidebar.vue'
import MainHeader from '@/components/MainHeader.vue'

import { useApi } from "@/composition/useApi.js"
import { useStore } from 'vuex'
import { onMounted } from 'vue'
export default {
  components:{
    MainSidebar,
    MainHeader
  },
  created(){
      document.title = "Monoclick Admin | Альянс-ЮГ";
  },
  setup(){
    const store = useStore()
    const {updateCSRF} = useApi()

    store.dispatch('authUser')
    const clearEvents = ()=>{
      if(store.state.eventsFolders.isMacrosList)
        store.commit('setVisibleMacrosList', false)
    }

    onMounted(()=>{
      localStorage.clear()
      updateCSRF().then(()=>{
        store.commit('setTokenCSRF')
      })
      
    })

    return{
      clearEvents
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/styles/normalize/normalize.css';
@import '@/assets/styles/index.scss';

html{
  width: 100vw;
  overflow: hidden;

}
#app{
  background-color: $backgroundMain;
  width: 100%;
}
.wrapper {
  @include fontStyle;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $backgroundMain;
  &__sidebar{
    width: $sidebarWidth;
    height: 100vh;
    background-color: $backgroundSidebar;
  }
  &__main{
    flex: 1 1 auto;
    width: calc(100vw - 280px);
    min-width: 860px;
  }
  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $headerHeight;
    background-color: $backgroundHeader;
  }
  &__page{
    position: relative;
    height: calc(100vh - $headerHeight)
  }
}


</style>
