
<!--    =  data(обязателен) - это объект с полями. Должен содержать минимум .name
        = .dataCheckbox(поле, необяз.) - уникальный параметр, отвечающий за связь label и checkbox(если не указать, по умолчанию будет подставлено имя)
        = .cheked(поле, необяз.) - если не передавать параметр, то по умлочанию, изначально все чекбоксы не активны; -->
<template>
    <div class="checkbox" v-if="data.name && data.name.length">
        <input class="checkbox-input" ref="inputCheckbox" type="checkbox" :checked="checked" 
            :id="data.dataCheckbox? data.dataCheckbox :data.name"
            @change="dataCheckboxInput(data.name)" >
        <label class="checkbox-label" :for="data.dataCheckbox?data.dataCheckbox:data.name">{{data.name}}{{ visibleDots? ' :': null}}</label>
    </div>
</template>
  
<script>
import { ref } from 'vue'
  export default {
    components: {
    },
    props:{
        data: {
            type: Object,
            default: () => {}
        },
        checked:{
            type: Boolean,
            default:false
        },
        visibleDots:{
            type: Boolean,
            default: false 
        }
    },
    setup(props, {emit}){
        const inputCheckbox = ref()

        const dataCheckboxInput = (name)=>{
            // передаем состояние и навание
            emit('dataCheckbox', inputCheckbox.value.checked, name, inputCheckbox.value)
        }

        return{
            inputCheckbox,
            dataCheckboxInput
        }
    }
  }
</script>
<style lang="scss" >
// стили LEFT и RIGHT у чекбокса, а также отступ label от чекбоска задавать самому
@import '@/assets/styles/index.scss';
.checkbox{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    &-input{
        appearance: none;
    }
    &-label{
        display: inline-block;
        width: 100%;
        padding-right: 10px;
        cursor: pointer;
    }
    &-label::after{
        content: "";
        display: inline-block;
        width: 17px;
        height: 17px;
        border: 1px solid #c4c4c4;
        position: absolute;
        top: calc(50% - 9px);
        border-radius: 4px;
        background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
    }
    &-input:checked + &-label::after{
        background-color: $buttonColor;
        border: none;
    }
}
</style>
