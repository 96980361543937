<template>
    <button class="btn" :disabled="disabled" :class="{disabled : disabled}">
      <slot></slot>
    </button>
</template>
  
<script>
  export default {
    components: {
    },
    props:{
        data: {
            type: Array,
            default: () => []
        },
        disabled: {
          type: Boolean,
          default: false
        }
    }
  }
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.btn{
  // height: 100%;
  color: #fff;
  background-color: $buttonColor;
  border-color: $buttonColor;
  border: 1px solid transparent;
  padding: 9px 12px;
  text-align: center;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover{
    color: #fff;
    background-color: $buttonHover;
    border-color: $buttonHover;
  }
  &.disabled{
    color: #fff;
    background-color: #e8e2e2;
    border-color: #e8e2e2;
    cursor: not-allowed;
  }
}
</style>