import { useApi } from "@/composition/useApi.js"
export default{
    state: ()=>({
        errorTextRequest: null, // тексты об ошибках
        addingModalIsShow: false,   // открыта ли модалка добавления
        allSheduledReports: [],  // список всех существующих запланированных отчетов
        allUsers: [],   // список всех пользователей
        editingSheduledReport: null, // редактируемый запланированный отчет
        isSpinerSR: true,   // спиннер номер 1
    }),
    mutations:{
        setAllSheduledReports(state, payload){
            state.allSheduledReports = payload
        },
        setAllUsers(state, payload){
            // делаем изначально все чекбоксы неактивными
            payload.forEach(user => user.checked = false)
            state.allUsers = payload
        },
        setAddingModalVisibility(state, bool){
            state.addingModalIsShow = bool
        },
        setSpinerSheduledReports(state, bool){
            state.isSpinerSR = bool
        },
        setSelectAllUsers(state, bool){
            // устанавливаем флажки на всех чекбоксах 
            state.allUsers.forEach(user => user.checked = bool)
        },
        setEditingSheduledReport(state, payload){
            state.editingSheduledReport = payload
        },
        setToggleCheckbox(state, payload){
            // выбор и снятие чекбокса
            state.allUsers.forEach(user=>{
                if(user.id == payload.id){
                    user.checked = payload.isChecked
                }
            })
        },
        setSomeCheckboxes(state, ids){
            // выбор нескольких чекбоксов
            state.allUsers.forEach((user) => user.checked = ids.includes(user.id) )
        },
        //поиск и обновление
        searchUsers(state, dataInput){
            document.querySelectorAll('.users .users__tbody .users__tbody-name').forEach(colName =>{
                if( colName.innerText.toLowerCase().includes(dataInput.toLowerCase()) )
                    colName.parentNode.style.display = "table-row"
                else
                    colName.parentNode.style.display = "none"
            })
        },
        searchUsersUpdate(){
            document.querySelectorAll('.users .users__tbody tr').forEach(row =>{
                row.style.display = "table-row"
            })
        },
        // Показ ошибки
        setErrorTextRequest(state, text){
            state.errorTextRequest = text
            setTimeout(()=> state.errorTextRequest = null, 5000)
        },
    },
    actions:{
        // Получить все запланированные отчеты
        getAllSheduledReports({commit}){
            commit('setSpinerSheduledReports', true)
            const { api } = useApi();
            return api.get(`/reports/senders`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setAllSheduledReports', r.data)
                        commit('setSpinerSheduledReports', false)
                    }
                    return r.status
                })
        },
        // Получить все папки для пользователя
        getSheduledReportsUsers({commit}, id){
            const { api } = useApi();
            return api.get(`/reports/senders/${id}/users`)
                .then((r) => {
                    // отмечаем чекбоксы
                    if(r.status == 200)
                        commit('setSomeCheckboxes', r.data.map(itm => itm.id))
                    return r.status
                })
        },
        // Получить всех пользователей
        getAllUsers({commit}){
            const { api } = useApi();
            return api.get(`/users`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setAllUsers', r.data)
                    }
                    return r.status
                })
        },
        createSheduledReport({commit, dispatch}, data){
            const { api } = useApi();
            return api.post(`/reports/senders`, data)
                .then((r) => {
                    // обновление страницы после добавления запланированного отчета
                    dispatch('getAllSheduledReports')
                    // закрываем модалку
                    commit('setAddingModalVisibility', false)
                    return r.status
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        updateSheduledReport({commit, dispatch}, data){
            const { api } = useApi();
            return api.put(`/reports/senders/${data.id}`, data.body)
                .then(() => {
                    // обновление страницы после добавления запланированного отчета
                    dispatch('getAllSheduledReports')
                    // закрываем модалку
                    commit('setAddingModalVisibility', false)
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        removeSheduledReport({dispatch}, id){
            const { api } = useApi();
            return api.delete(`/reports/senders/${id}`)
                .then((r) => {
                    // обновление страницы после добавления запланированного отчета
                    dispatch('getAllSheduledReports')
                    return r.status
                })
        }
    }
}
