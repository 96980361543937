<template>
    <div class="editBtn" v-if="edit">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
        </svg>
    </div>
    <div class="deleteBtn" v-else>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
        </svg>
    </div>
</template>
  
<script>
  export default {
    components: {
    },
    props:{
        data: {
            type: Array,
            default: () => []
        },
        edit: {
            type: Boolean,
            default: true
        }
    }
  }
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.editBtn{
    width: 34px;
    height: 34px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin: 0 auto;
    padding:0.25rem 0.5rem;
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
    & svg{
        width: 100%;
        height: 100%;
    }
    &:hover{
        color: #000;
        background-color: #ffca2c;
        border-color: #ffc720;
    }
}
.deleteBtn{
    width: 34px;
    height: 34px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin: 0 auto;
    padding:0.25rem 0.5rem;
    padding-right: 0.55rem;
    padding-left: 0.45rem;
    color: #fff;
    background-color: $buttonColor;
    border-color: $buttonColor;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
    & svg{
        width: 115%;
        height: 100%;
    }
    &:hover{
        background-color: $buttonHover;
        border-color: $buttonHover;
    }
}
</style>