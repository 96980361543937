<template>
    <div class="modalError">
        {{infoText}}
    </div>
</template>
<script>
export default {
    name:'modalError',
    props:{
        infoText: {
            type: String,
            default: 'Error'
        }
    },
}
</script>
<style lang="scss" scoped>
.modalError{
    display: inline-block;
    width: 100%;
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    padding: 1rem;
    border-radius: 6px;
}
</style>