import { useApi } from "@/composition/useApi.js"
import { searchTerminalsFuncions } from "@/composition/searchAllTerminals.js"
export default{
    state: ()=>({
        errorTextRequest: null,

        isPageAddingEvents: false,

        allFoldersEvents: [],
        activeFolderEvents: null,

        activeEventsForFolder: [],

        isMacrosList: false,

        isSpinerMainFolders: true,
        isSpinerMainList: true,
        // редактирование и удаление
        eventEditing: null
    }),
    mutations:{
        setErrorTextRequest(state, text){
            state.errorTextRequest = text
            setTimeout(()=> state.errorTextRequest = null, 3000)
        },

        // ГЛАВНАЯ СТРАНИЦА
        setAllFoldersEvents(state, data){
            state.allFoldersEvents = data
            // доп обработка для работы поиска (вводит доп поля для открисовки папок)
            if(state.allFoldersEvents){
                // получение данных из файла "поиски для терминалов"
                const { visibleAllFolders } = searchTerminalsFuncions()
                // закрытие всех папок ( закрытие всех внутренних папок , отрисовка всех папок(отрисовывваем все) )
                visibleAllFolders(state.allFoldersEvents, false, true)                
            }
        },

        setActiveFolderEvents(state, folder){
            state.activeFolderEvents = folder
        },

        setEventsForFolder(state, events){
            state.activeEventsForFolder = events
        },

        // СОЗДАНИЕ / РЕДАКТИРОВАНИЕ 
        setPageAddingEvents(state, bool){
            state.isPageAddingEvents = bool
        },

        setAllMacros(state, macros){
            state.allMacros = macros
        },

        setVisibleMacrosList(state, bool){
            state.isMacrosList = bool
        },
        // АКТИВНОСТЬ СПИННЕРОВ НА ГЛАВНОЙ СТРАНИЦЕ
        setSpinerMainFolders(state, bool){
            state.isSpinerMainFolders = bool
        },
        setSpinerMainList(state, bool){
            state.isSpinerMainList = bool
        },
        // РЕДАКТИРОВАНИЕ и УДАЛЕНИЕ
        setEventEditing(state, event){
            state.eventEditing = event
        }
    },
    actions:{
        getEventsFolder({commit}, id){
            commit('setSpinerMainList', true)
            const { api } = useApi();
            return api.get(`/events?folderId=${id}`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setEventsForFolder', r.data)
                        commit('setSpinerMainList', false)
                    }         
                    return r.status
                })
        },
        getMacrosEvent({commit}){
            const { api } = useApi();
            return api.get(`/events/macros`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setAllMacros', r.data)
                    return r.status
                })
        },
        createEvent({commit}, data){
            const { api } = useApi();
            return api.post(`/events`, data)
                .then((r) => r.status)
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        eventEditing({commit}, data){
            const { api } = useApi();
            return api.put(`/events/${data.id}`, data)
                .then((r) => r.status)
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        eventDeliting(state, id){
            const { api } = useApi();
            return api.delete(`/events/${id}`)
                .then((r) => r.status)
        }
    }
}
