import { useApi } from "@/composition/useApi.js"
export default{
    state: ()=>({
        isVisibleAddingPage: false,
        mainBlockSensor: null,
        isSpinerSensorsTable: true,
        // ГАЛВНЫЙ БЛОК
        sensorsTypesMandatory: null,
        getTypeMandatory: null,
        nameMandatory: null,
        schemaMandatory: null,
        // спинер загрузки блоков датчиков
        isAddingSensors: false,
        // "ТАРИРОВАНИЕ" БЛОК
        isVisibleInfo: false,
        // "СОБЫТИЯ" БЛОК
        dataEvents: [],
        // СБОР ДАННЫХ НА ОТПРАВКУ
        allKeysSchema: [], //общие данные всех ключей при получении схемы
        alldataSchema: {}, //объединение всех данных, после заполнения полей клиентом

        allDataInputsSensors: [],
        inputs: [],

        calibration: null,
        selectTypeCalibration: null,

        // датчики терминала
        sensorsTerminals: null,
        // редактирование датчика
        isEditSensor: false,
        editingSensor: {},
        isDataFilingCalibration: false,
        //статические данные
        titleNameBlock: { name: 'Обязательные' },
        inputsMandatory:[
            {
                name: 'Название',
                inputName: 'dutName',
                type: 'text',
                placeholder:'Имя'
            },
        ],
        typesCalibration:[
            { name: 'Пропорциональная', type: 'PROPORTIONAL' },
            { name: 'Линейная', type: 'LINEAR' },
            { name: 'Кусочно-линейная', type: 'PIECEWISE_LINEAR'},
            { name: 'Полином', type: 'POLYNOMIAL' },
        ],
        // ошибка при запросе
        textError: null,
        isEmptyEventsTerminal: false
    }),
    mutations:{
        setTitleNameBlock(state, nameBlock){
            state.titleNameBlock = nameBlock
        },
        setSpinerSensorsTable(state, bool){
            state.isSpinerSensorsTable = bool
        },
        // ГЛАВНЫЙ
        setTypesMandatory(state, data){
            state.sensorsTypesMandatory = data
        },
        setMainBlockSensor(state, data){
            state.mainBlockSensor = data
        },

        setSchemaMandatory(state, data){
            state.schemaMandatory = data
            // очищение 
            state.allDataInputsSensors = []
            // обработка. Для изменения корнструкции select (для правильного вывода)
            if(state.schemaMandatory && state.schemaMandatory.length)
                state.schemaMandatory.forEach( item => {
                    if(item.type == 'CUSTOM' && item.properties && item.properties.length){
                        item.properties.forEach( el =>{
                            el.name = `${el.name}`
                            if(el.type == 'SELECT' && !el.options.select.length){
                                return el.options.select = el.options.select.map( () =>{ return {type : null,  name: 'Нет данных'} })
                            }
                            if(el.type == 'SELECT')
                                el.options.select = el.options.select.map(select =>{ return {type : select.key,  name: select.value} })
                            // задаем значения по умолчанию если человек не станет заполнять их (для отправки на сервер)  
                            if(el.type == 'CHECKBOX'){
                                el.dataCheckbox = el.key
                                state.allDataInputsSensors.push( {inputKey: el.key, valueInputs: false, type: el.type} )
                            }
                            if(el.type == 'SELECT' && !el.isArray)
                                state.allDataInputsSensors.push( {inputKey: el.key, valueInputs: el.options.select[0].type, type: el.type} )

                            if(el.type == 'INT' || el.type == 'FLOAT')
                                state.allDataInputsSensors.push( {inputKey: el.key, valueInputs: 0, type: el.type} )
                            if(el.type == 'STRING')
                                state.allDataInputsSensors.push( {inputKey: el.key, valueInputs: '', type: el.type} )
                        })
                    }
                })
        },

        setAllKeysSchema(state){
            state.allKeysSchema = []
            // получаем массив из всех действующих ключей инпутов (для заполенения данных при отправке на сервер)
            state.schemaMandatory.forEach(block =>{
                if(block.type == 'CUSTOM' && block.properties && block.properties.length)
                    state.allKeysSchema.push(...Object.values( block.properties.map(input => input.key) ))
            })
        },
        setNameMandatory(state, name){
            state.nameMandatory = name
        },
        setTypeMandatory(state, data){
            state.getTypeMandatory = data
        },

        setAlldataSchema(state, arr){
            if(arr && arr.length){
                arr.forEach(item => {
                   if(state.allKeysSchema.includes(item.inputKey)){
                        state.alldataSchema[item.inputKey] = Array.isArray(item.valueInputs)? item.valueInputs.map(item => item.value): item.valueInputs
                   } 
                })
                
            }         
        },

        setInputs(state, data){
            data.forEach(itemData =>{
                // добавление новых инпутов
                if(!state.inputs.map(input => input.inputKey).includes(itemData.inputKey))
                    return state.inputs.push(itemData)
                // изменение инпута
                state.inputs.forEach(input =>{
                    if(itemData.inputKey == input.inputKey)
                        input = itemData
                })

            })
        },
        clearInputs(state){
            state.inputs = []
        },
        setallDataInputsSensors(state, input){
            // добавление новых инпутов
            if( !state.allDataInputsSensors.map(item => item.inputKey).includes(input.inputKey) )
                return state.allDataInputsSensors.push(input)
            // изменение инпутов
            let changeInput = state.allDataInputsSensors.find(item => item.inputKey == input.inputKey)
            changeInput.valueInputs = input.valueInputs
        },

        setCalibration(state, data){
            state.calibration = data
        },
        setSelectTypeCalibration(state, type){
            state.selectTypeCalibration = type
        },

        // получение всех датчиков терминала и удаление
        setSensorsTerminals(state, data){
            state.sensorsTerminals = data
            // добавляем ключ для поиска
            if(state.sensorsTerminals && state.sensorsTerminals.length)
                state.sensorsTerminals.forEach(sensor  => sensor.isVisibleCard = true )
        },
        deleteSensorsTerminals(state, data){
            state.sensorsTerminals = state.sensorsTerminals.filter(sensor => sensor.id != data.id)
        },
        // "СОБЫТИЕ" БЛОК
        setDataEvents(state, event){
            state.dataEvents = event
        },
        deleteEmptyKeysEvents(state){
            state.dataEvents.forEach( obj => delete obj.idEvent)
        },
        // "ТАРИРОВАНИЕ" БЛОК
        setVisibleInfo(state, bool){
            state.isVisibleInfo = bool
        },
        // отрисовка страницы Датчиков
        setVisibleAddingPage(state, bool){
            state.isVisibleAddingPage = bool
        },

        // редактирование датчиков
        setEditingSensor(state, data){
            state.editingSensor = data
        },
        setIsEditSensor(state, bool){
            state.isEditSensor = bool
        },
        setIsDataFilingCalibration(state, bool){
            state.isDataFilingCalibration = bool
        },

        setTextError(state, text){
            state.textError = text
            setTimeout(()=> state.textError = null, 3000)
        },
        // спинер загрузки блоков датчиков
        setIsAddingSensors(state, bool){
            state.isAddingSensors = bool
        },
                
        setEmptyEventsTerminal(state, bool){
            state.isEmptyEventsTerminal = bool
        }
    },
    actions:{
        getTypesMandatory({commit}){
            const { api } = useApi();
            return api.get(`/terminals/sensors/types`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setTypesMandatory', r.data)
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setTextError', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        getSchemaMandatory({commit}, data){
            const { api } = useApi();
            // спиннер загрузки
            commit('setIsAddingSensors', true)
            // загрузка
            return api.get(`/terminals/sensors/schema/${data.typeSensor}?terminalId=${data.idTerminal}`)
                .then((r) => {
                    commit('setIsAddingSensors', false)
                    if(r.status == 200){
                        commit('setSchemaMandatory', r.data)
                        commit('setAllKeysSchema')
                    }
                })
                .catch((r)=>{
                    commit('setIsAddingSensors', false)
                    if(r.response.data.message)
                        commit('setTextError', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        createSensorsTerminal({commit}, data){
            const { api } = useApi();
            return api.post(`/terminals/sensors`, data)
                .then((r)=> r.status)
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setTextError', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        deleteSensorsTerminal({commit}, data){
            const { api } = useApi();
            return api.delete(`/terminals/sensors/${data.id}`)
                .then((r) => {
                    if(r.status == 204)
                        commit('deleteSensorsTerminals', data)
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setTextError', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        editingSensorsTerminal({state, commit}, data){
            const { api } = useApi();
            return api.put(`/terminals/sensors/${state.editingSensor.id}`, data)
                .then((r)=> r.status)
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setTextError', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        getSensorsTerminal({commit}, id){
            const { api } = useApi();
            return api.get(`/terminals/sensors?terminalId=${id}`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setSensorsTerminals', r.data)
                        commit('setSpinerSensorsTable', false)
                    }                  
                })
        },
    }
}