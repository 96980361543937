import { useApi } from "@/composition/useApi.js"
import { searchTerminalsFuncions } from "@/composition/searchAllTerminals.js"
export default{
    state: ()=>({
        allFoldersTerminalsCar: null,
        allTerminalsCar: null,
        terminalsActiveFolder: null,

        activeFolderNameCar: null,
        //ПЕРЕМЕННАЯ отвечающая за вывод ошибки при удалении терминала
        isErrorDeleteTerminals: false
    }),
    mutations:{
        // получение данных на отрисовку
        setAllTerminalsCar(state, data){
            data.forEach(itm => itm.checkedForMove = false) // состояние чекбокса для перемещения в другую папку
            state.allTerminalsCar = data
        },
        setActiveFolderNameCar(state, folder){
            state.activeFolderNameCar = folder
        },
        setAllFoldersTerminalsCar(state, data){
            state.allFoldersTerminalsCar = data
            // доп обработка для работы поиска (вводит доп поля для открисовки папок)
            if(state.allFoldersTerminalsCar){
                // получение данных из файла "поиски для терминалов"
                const { visibleAllFolders } = searchTerminalsFuncions()
                // закрытие всех папок ( закрытие всех внутренних папок , отрисовка всех папок(отрисовывваем все) )
                visibleAllFolders(state.allFoldersTerminalsCar, false, true)                
            }

        },
        // ИЗМЕНЕНИЕ ТЕРМИНАЛОВ
        setSearchTerminalsCar(state, data){
            state.allTerminalsCar = data
        },
        // ОШИБКА ПРИ УДАЛЕНИИ ТЕРМИНАЛА на главное странице
        errorDeleteTerminalsCar(state, bool){
            state.isErrorDeleteTerminals = bool
            setTimeout(()=> state.isErrorDeleteTerminals = false, 3000)
        },
        // Перемещение терминалов из папки в папку
        selectOneCarCheckboxForMove(state, payload){
            // выбор одного терминала для перемещения
            state.allTerminalsCar.find(itm => itm.id === payload.id).checkedForMove = payload.bool
        },
        selectAllCarCheckboxesForMove(state, bool){
            // выбор всех терминалов для перемещения
            state.allTerminalsCar.forEach(itm => {
                itm.checkedForMove = bool
            })
        },
        removeTerminalCar(state, arr){
            // удаление термингала из папки после перемещения
            arr.forEach(itm => {
                state.allTerminalsCar = state.allTerminalsCar.filter(terminal => terminal.id !== itm)
            })
        },
    },
    actions:{
        getAllTerminalsCar({commit}){
            const { api } = useApi();
            return api.get(`/terminals/car`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setAllTerminalsCar', r.data)
                })
        },
        getTerminalsCarFolder({commit}, id){
            const { api } = useApi();
            return api.get(`/terminals/folder/${id}?type=car`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setAllTerminalsCar', r.data)
                })
        },
        changeCarTerminalFolder(state, payload){
            const { api } = useApi();
            return api.patch(`/terminals/folder`, payload)
        },
    }

}