import { useApi } from "@/composition/useApi.js"
export default{
    state: ()=>({
        errorTextRequest: null, // тексты об ошибках
        usersModalIsShow: false,   // открыта ли модалка добавления пользователей
        allUsers: [],   // список всех пользователей
        allFolders: [],  // список всех папок
        allRoles: [],  // список всех ролей
        editingUser: null, // редактируемый пользователь
        isLoader: true,   // спиннер
    }),
    getters: {
        getFoldersArr(state){
            // формируем массив обьектов из id папаок для submit
            const res = state.allFolders.filter(folder => folder.checked).map(item => item.id)
            return res
        }
    },
    mutations:{
        setUsersList(state, payload){
            state.allUsers = payload
        },
        setFoldersList(state, payload){
            // устанавливаем все флажки не активными
            payload.forEach(folder => folder.checked = false)
            state.allFolders = payload
        },
        setRolesList(state, payload){
            state.allRoles = payload
        },
        setUsersModalVisibility(state, bool){
            state.usersModalIsShow = bool
        },
        setSpinerUsers(state, bool){
            state.isLoader = bool
        },
        setEditingUser(state, payload){
            state.editingUser = payload
        },
        setToggleFolderCheckbox(state, payload){
            // выбор и снятие чекбокса
            const elem = state.allFolders.find(folder=>folder.id == payload.id)
            elem.checked = payload.isChecked
        },
        setSomeFoldersCheckboxes(state, ids){
            // выбор нескольких чекбоксов
            state.allFolders.forEach((user) => user.checked = ids.includes(user.id) )
        },
        setUserErrorTextRequest(state, text){
            state.errorTextRequest = text
            setTimeout(()=> state.errorTextRequest = null, 5000)
        }
    },
    actions:{
        // Получить всех пользователей
        getUsersList({commit}){
            const { api } = useApi();
            return api.get(`/users`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setUsersList', r.data)
                        commit('setSpinerUsers', false)
                    }
                    return r.status
                })
        },
        // Получить все папки для пользователя
        getUsersAllowFolders({commit}, id){
            const { api } = useApi();
            return api.get(`/users/${id}/allow-folders`)
                .then((r) => {
                    // отмечаем чекбоксы
                    if(r.status == 200)
                        commit('setSomeFoldersCheckboxes', r.data.map(itm => itm.id))
                    return r.status
                })
        },
        getRolesList({commit}){
            const { api } = useApi();
            return api.get(`/role`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setRolesList', r.data)
                    }
                    return r.status
                })
        },
        getFoldersList({commit}){
            const { api } = useApi();
            return api.get(`/folders`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setFoldersList', r.data)
                    }
                    return r.status
                })
        },
        createUser({commit, dispatch}, data){
            const { api } = useApi();
            return api.post(`/users`, data)
                .then((r) => {
                    // обновление страницы после добавления пользователя
                    dispatch('getUsersList')
                    // закрываем модалку
                    commit('setUsersModalVisibility', false)
                    return r.status
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setUserErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        updateUser({commit, dispatch}, data){
            const { api } = useApi();
            return api.put(`/users/${data.id}`, data.body)
                .then(() => {
                    // обновление страницы после добавления пользователя
                    dispatch('getUsersList')
                    // закрываем модалку
                    commit('setUsersModalVisibility', false)
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        removeUser({dispatch}, id){
            const { api } = useApi();
            return api.delete(`/users/${id}`)
                .then((r) => {
                    // обновление страницы
                    dispatch('getUsersList')
                    return r.status
                })
        }
    }
}
