import { useApi } from "@/composition/useApi.js"
export function searchTerminalsFuncions() {
    // isOpen - открытие внутренних терминалов
    // isVisibleFolder - видимость папок

    // открытие/закрытие всех папок перед поиском
    const showAllFolders = (arr, isOpenInnerTerminals, isVisibleFolder)=>{
        arr.forEach(folder =>{
            folder.isVisibleFolder = isVisibleFolder
            if(folder.folders && folder.folders.length){
                folder.isOpen = isOpenInnerTerminals
                showAllFolders(folder.folders, isOpenInnerTerminals, isVisibleFolder)
            }else{
                folder.isOpen = false
            }
        })
    }
    // скрытие всех папок (открыв вложенные папки)
    const inVisibleAllFolders = (arr) => showAllFolders(arr, true, false)
    // отображение всех папок (скрыв вложенные папки)
    const visibleAllFolders = (arr) => showAllFolders(arr, false, true)

    // преобразование папок в одномерный массив
    function foldersToFlatArray(allfolders) {
        let arrFoldersToFlat = [];
        allfolders.forEach(folder =>{
            arrFoldersToFlat.push(folder);
            if(folder.folders && folder.folders.length)
                arrFoldersToFlat = [ ...arrFoldersToFlat, ...foldersToFlatArray(folder.folders) ];
        })
        return arrFoldersToFlat;
    }
    // поиск папки по имени
    function findByName(folders, value) {
        return folders.filter(v => {
            if(v.name.toLowerCase().includes(value.toLowerCase())){
                v.isVisibleFolder = true
                return v
            }
        });
    }
    // открытие всех родительских папок , которые содержат открытую папку
    function openFolderByIds(folders, ids) {
        if(ids.length === 0){
            return; 
        }
        const findFolders = folders.filter(v => ids.includes(v.id))
        const findIds = findFolders.map(v => v.parentFolderId).filter(v => v);
        for(const folder of findFolders){
            folder.isOpen = true;
            folder.isVisibleFolder = true
        } 
        openFolderByIds(folders, findIds);
    }

    const findByNameFolders = (folders, value) =>{
        const flatFolders = foldersToFlatArray(folders);
        const find = findByName(flatFolders, value);

        openFolderByIds(flatFolders, find.map(v => v.parentFolderId).filter(v => v))
    }

    // Таймер задержки
    function debounce(fn, ms) {
        let timeout;
        return function (...arg){
            clearTimeout(timeout);
            timeout = setTimeout(()=> { fn.apply(this, arg) }, ms)
        }
    }
    
    // ТЕРМИНАЛЫ МАШИН (главная)
    const getSearchTerminalsCar = (value, allFolders)=>{
        const { api } = useApi()
        let result;
        return api.get(`/terminals/search?q=${value}&type=car`)
            .then((r)=>{
                if(r.status == 200){
                    result = r.data
                    // обработка массива для отображения имени папки терминалов в шаблоне (при поиске)
                    result.forEach(terminal => terminal.isVisibleForSearch = true)
                        foldersToFlatArray(allFolders).forEach(folder=> {
                            result.forEach(terminal =>{
                                if(terminal.folderId == folder.id){
                                    // отображение блока в шаблоне с информацией родительской папки для терминала
                                    terminal.isVisibleForSearch = true
                                    // вывод имени родительской папки в шаблоне для терминала
                                    terminal.folderName = folder.name
                                }
                            })
                        })
                    return result                    
                }
            })
        
    }
    // ТОПЛИВНЫЕ ТЕРМИНАЛЫ (главная)
    const getSearchTerminalsFuel = (value, allFolders)=>{
        const { api } = useApi()
        let result;
        return api.get(`/terminals/search?q=${value}&type=fuel`)
            .then((r)=>{
                if(r.status == 200){
                    result = r.data
                    // обработка массива для отображения имени папки терминалов в шаблоне (при поиске)
                    result.forEach(terminal => terminal.isVisibleForSearch = true)
                        foldersToFlatArray(allFolders).forEach(folder=> {
                            result.forEach(terminal =>{
                                if(terminal.folderId == folder.id){
                                    // отображение блока в шаблоне с информацией родительской папки для терминала
                                    terminal.isVisibleForSearch = true
                                    // вывод имени родительской папки в шаблоне для терминала
                                    terminal.folderName = folder.name
                                }
                            })
                        })                        
                    return result
                }
            })
    }
    // КАРТЫ ВОДИТЕЛЕЙ
    // в модальном окне
    function searchDriverCards(arrayCards, value){
        return arrayCards.forEach(card => card.isVisibleCard = card.car.toLowerCase().includes(value.toLowerCase()) || (card.rfid).toString().includes(value))
    }

    // ГРУППЫ КАРТ
    // в модальном окне
    function searchGroupCards(arrayGroupCards, value){
        return arrayGroupCards.forEach(card => card.isVisibleCard = card.name.toLowerCase().includes(value.toLowerCase()))
    }
    // в добавленных
    function searchGroupList(arrayGroupCards, value){
        return arrayGroupCards.forEach(card => card.isVisibleCard = card.name.toLowerCase().includes(value.toLowerCase()))
    }
    // КАРТЫ ДОСТУПА
    // в модальном окне
    function searchAccessCards(arrayAccessCards, value){
        return  arrayAccessCards.forEach(card => card.isVisibleTerminal = (card.car.toLowerCase().includes(value.toLowerCase()) || (card.rfid).toString().includes(value)))
    }
    // в добавленных
    function searchAccessList(arrayAccessCards, value){
        return arrayAccessCards.forEach(card => card.isVisibleCard = card.car.toLowerCase().includes(value.toLowerCase()) || (card.rfid).toString().includes(value))
    }
    // ДАТЧИКИ
    // в добавленных
    function searchSensorsList(arraySenosrs, value){
        return arraySenosrs.forEach(sensor => sensor.isVisibleCard = sensor.name.toLowerCase().includes(value.toLowerCase()))
    }    
    return{

        getSearchTerminalsCar,
        getSearchTerminalsFuel,

        visibleAllFolders,
        inVisibleAllFolders,
        findByNameFolders,


        searchDriverCards,
        searchAccessCards,
        searchAccessList,
        searchGroupCards,
        searchGroupList,
        searchSensorsList,

        debounce
    }

}