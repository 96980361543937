import { createRouter, createWebHistory } from 'vue-router'
import BillingPage from '@/pages/BillingPage.vue'


const routes = [
  {
    path: '/frame/users-main',
    name: 'usersMain',
    meta: {route:'usersMain', nameTitle:'Пользователей'},
    component: ()=> import('@/pages/UsersPage.vue')
  },
  {
    path: '/frame/billing',
    name: 'billing',
    meta: {route:'billing', nameTitle:'Выставлений счетов'},  // доп данные(статично вводим) для отрисовки Title в Header 
    component: BillingPage
  },
  {
    path: '/frame/terminals/fuel',
    name: 'terminalsFuel',
    alias: '/', // роут по умолчанию
    meta: {route:'terminals', nameTitle:'Топливных терминалов'},
    component: ()=> import('@/pages/TerminalsFuelPage.vue')
  },
  {
    path: '/frame/terminals/car',
    name: 'terminalsCar',
    meta: {route:'terminals', nameTitle:'Терминалов машин'},
    component: ()=> import('@/pages/TerminalsCarPage.vue')
  },
  {
    path: '/frame/events',
    name: 'events',
    meta: {route:'events', nameTitle:'Событий'},
    component: ()=> import('@/pages/EventsPage.vue')
  },
  {
    path: '/frame/fuelCardServices',
    name: 'fuelCardServices',
    meta: {route:'fuelCardServices', nameTitle:'Сервисов топливных карт'},
    component: ()=> import('@/pages/FuelCardServices.vue')
  },
  {
    path: '/frame/groups',
    name: 'groups',
    meta: {route:'groups', nameTitle:'групп'},
    component: ()=> import('@/pages/GroupsPage.vue')
  },
  {
    path: '/frame/scheduledReports',
    name: 'scheduledReports',
    meta: {route:'scheduledReports', nameTitle:'отчётов'},
    component: ()=> import('@/pages/ScheduledReportsPage.vue')
  },
  {
    path: '/frame/geozones',
    name: 'geozones',
    meta: {route:'geozones', nameTitle:'геозон'},
    component: ()=> import('@/pages/GeozonesPage.vue')
  },
  {
    path: '/frame/roles',
    name: 'roles',
    meta: {route:'roles', nameTitle:'ролей'},
    component: ()=> import('@/pages/RolesPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
