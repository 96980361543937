<template>
    <div class="writeOffTerminals"  v-if="(dataPerDay.details && dataPerDay.details.length)">
        <div class="writeOffTerminals__time-wrap">
            <div class="writeOffTerminals__time">
                За {{new Date(dataPerDay.createdAt).toLocaleString()}}
            </div>
            <div class="writeOffTerminals__close" @click="closeModal">
                <ButtonComponent>Закрыть</ButtonComponent>
            </div>
        </div>
        <div class="writeOffTerminals__table-wrap">
            <table class="writeOffTerminals__table">
                <thead class="writeOffTerminals__thead">
                    <tr>
                        <th><div>#</div></th>
                        <th><div>Тариф</div></th>
                        <th><div>Сумма</div></th>
                        <th class="writeOffTerminals__col last"><div>Терминал</div></th>
                    </tr>
                </thead>
                <tbody class="writeOffTerminals__tbody">
                    <tr v-for="(data, idx) in allTerminals" :key="idx" >
                        <td>{{ idx + 1 }}</td>
                        <td >{{data.price}}</td>
                        <td ref="blockTotalSum">{{data.sum}}</td>
                        <td class="writeOffTerminals__col last">{{data.name}}</td>
                    </tr>
                </tbody>
                <tfoot class="writeOffTerminals__tfoot">
                    <tr>
                        <td class="writeOffTerminals__tfoot-total" colspan="4"> Итого: {{dataPerDay.sum}} </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>
<script>
import ButtonComponent from "@/components/UI/ButtonComponent.vue";
import { ref } from 'vue';
export default {
    name: 'ModalWriteOffTerminals',
    components:{
        ButtonComponent
    },
    props:{
        dataPerDay:{
            type: Object,
            default: ()=>{}
        }
    },
    setup(props,{emit}){
        const rowsTable = ref()
        const allTerminals = ref([])
        props.dataPerDay.details.forEach(item =>{
            // формируем массив для модалки
            item.details.forEach(itm => {
                itm.price = item.price
                itm.sum = (item.sum / item.details.length).toFixed(2)
            })
            allTerminals.value.push(...item.details)
        })
        const closeModal = ()=>{
            emit('closeModal', false)
        }
        return{
            closeModal,
            rowsTable,
            allTerminals
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/styles/index.scss';
.writeOffTerminals{
    position: relative;
    min-width: 950px;
    height: 100%;
    padding: 5px 10px;
    margin-bottom: 15px;
    & th, & td{
        width: calc(100% / 5);
        text-align: center;
    }
    & tr{
        &:nth-child(2n) { background-color: #f2f2f2; }
    }
    & table{
        width: 100%;
        position: relative;
    }
    &__table{
        &-wrap{
            max-height: calc(70vh - 95px);
            overflow: auto;
        }
    }
    &__thead{
        position: sticky;
        top: 0;
        z-index: 1;
        background: $backgroundMain;
    }
    &__thead th div, td, &__tfoot td{
        height: 40px;
        padding: 0.5rem 0.5rem;
    }
    &__col{
        &.last{
            width: calc(100% / 5 * 2);
        }
    }
    td.last{
        text-align: start
    }
    &__tfoot td{
        border-bottom: 1px solid $rowHover;
        font-weight: 700;
        background-color: $backgroundMain;
    }
    &__time-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
}
</style>