<template>
    <table class="table writeOffRates" v-if="dataHeader && dataHeader.length">
        <caption>Отчет списания</caption>
        <thead class="table__head writeOffRates__thead-main">
            <tr class="writeOffRates__tr">
                <th class="writeOffRates__thTime" scope="col writeOffRates__col"  width="200">
                    <div style="width:200px">Время</div>
                </th>
                <th class="writeOffRates__th" scope="col writeOffRates__col" width="220">
                    <div style="width:220px"> Баланс "До"</div>
                </th>
                <th class="writeOffRates__th" scope="col writeOffRates__col" v-for="(rate, num) in dataHeader" :key="num"
                    :style="{'width':(100/(dataHeader.length+1))+'%'}" >
                    {{rate.price}}
                </th>
                <th class="writeOffRates__thTerminals" title="Информация о списании терминалов">
                    <div>
                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="1280.000000pt" height="1280.000000pt" viewBox="0 0 1280.000000 1280.000000"
                            >
                            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                            fill="#ffffff" stroke="none">
                            <path d="M6095 12794 c-1453 -77 -2788 -613 -3885 -1558 -138 -119 -447 -423 -570 -561 -517 -579 -915 -1221 -1194 -1928 -224 -567 -361
                                -1145 -423 -1792 -24 -252 -24 -858 0 -1110 108 -1120 456 -2103 1068 -3016 355 -531 836 -1049 1344 -1450 989 -780 2125 -1232 3410 -1356 252 -24 858 -24 1110 0 1113 107 2103 456 3004 1059 338 227 614 452 919 753 264 259
                                421 437 632 717 728 964 1148 2057 1267 3293 24 252 24 858 0 1110 -137 1422 -679 2669 -1617 3720 -123 138 -432 442 -570 561 -1031 889 -2265 1413 -3625 1539 -162 15 -716 27 -870 19z m525 -2385 c615 -61 1156 -302 1535 -683
                                246 -247 428 -573 514 -921 44 -178 56 -297 56 -575 0 -346 -31 -567 -111 -806 -138 -412 -413 -725 -835 -948 -218 -115 -423 -185 -771 -261 l-216 -48 -4 -311 c-5 -337 -8 -357 -68 -476 -51 -101 -155 -187 -281 -231 -94 -34 -285
                                -34 -384 -1 -170 57 -271 167 -322 352 -16 59 -18 117 -18 625 0 501 2 566 17 615 37 119 105 148 548 239 310 64 467 118 633 217 331 199 516 500 546 888 26 342 -74 602 -328 857 -112 112 -179 161 -301 220 -168 81 -318 113 -545 113 -309 1 -529
                                -57 -735 -192 -112 -74 -296 -258 -447 -447 -138 -172 -284 -318 -347 -346 -58 -25 -202 -39 -286 -28 -94 13 -164 51 -246 133 -123 123 -162 238 -151 449 17 311 118 549 341 800 246 275 590 494 996 631 406 137 777 178 1210 135z m-255 -6980 c67
                                -13 178 -69 246 -123 68 -55 124 -126 178 -226 184 -342 4 -770 -379 -901 -99 -34 -258 -32 -361 3 -109 38 -194 91 -275 172 -127 127 -181 278 -171 475 8 176 66 299 198 426 170 162 345 216 564 174z"/>
                            </g>
                        </svg>                        
                    </div>
                </th>
            </tr>
        </thead>
        <tbody class="table__tbody writeOffRates__tbody-main" >
            <tr class="writeOffRates__tr" v-for="invoice in dataBody" :key="invoice.id">
                <!-- +2 к colspan добавили : один - первый стобец вывода времени(статический), воторой - иконки (т к динамика только rates) -->
                <td class="writeOffRates__td" :colspan="(dataHeader.length+3)">
                    <div class="writeOffRates__tbody-title">{{invoice.invoiceNumber}}</div>

                    <table>
                        <tr class="writeOffRates-innerTable__tr" v-for="(element, idx) in invoice.rows" :key="idx"
                            :style="{
                                'background': (idx%2 == 0)?'#f2f2f2': '#fff'
                            }">   
                            <!-- +3 к colspan добавили : два - первый+второй стобец вывода времени, третий - иконки (т к динамика только rates) -->
                            <td :colspan="(dataHeader.length+3)">

                                <table class="tableRows">
                                    <!-- это строка в таблице будет отображаться если операция 'TOPUP' (пополнение) -->
                                    <tr class="tableRows__tr" v-if="element.operation == 'TOPUP'">
                                        <td class="tableRows__timeMain" @click="openBlock(element)" style="width:200px; background: #08c708">
                                            <div style="width:200px">{{new Date(element.createdAt).toLocaleString()}}</div>
                                        </td>
                                        <!-- ( dataHeader.length/(dataHeader.length+1) ) - это ширина всех 3х ячеек (3/4), т к мы их объединяли , а раньше каждая была (1/4) -->
                                        <td class="tableRows__rateMain  tableRows__rateMain-info--refill" :colspan="(dataHeader.length+1)" 
                                            @click="openBlock(element)">
                                            <div class="tableRows__rateMain-wrap">
                                                <div class="tableRows__rateMain-info tableRows__rateMain-sum" :data-sum="element.sum">
                                                    {{(element.sum > 0)? '+'+element.sum :  element.sum }}
                                                </div> 
                                            </div>
                                        </td>
                                        <td class="tableRows__rateMain-terminals tableRows__rateMain-info--refillEmpty table-hideInfo__emptyTd" @click="openInfoTerminals(element)">
                                            <div>                                               
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- Иначе отрисовываем все стоки по этому шаблону -->
                                    <tr class="tableRows__tr" v-else>
                                        <td class="tableRows__timeMain" @click="openBlock(element)" width="200">
                                            <div style="width:200px">{{new Date(element.createdAt).toLocaleString()}}</div>
                                        </td>
                                        <td class="tableRows__timeMain tableRows__timeMain-oldBalance" @click="openBlock(element)" width="220" >
                                            <div style="width:220px">{{element.oldBalance?element.oldBalance:'не было баланса'}}</div>
                                        </td>
                                        <td class="tableRows__rateMain"  v-for="(rate, i) in dataHeader" :key="i" :style="{'width':(100/(dataHeader.length))+'%'}"
                                            @click="openBlock(element)">

                                            <div class="tableRows__rateMain-wrap" >
                                                <div class="tableRows__rateMain-info dataTotal" :data-rate="rate.price">
                                                    {{element.details.length? element.details.filter(item=> item.price == rate.price).length? ( element.details.filter(item=> item.price == rate.price) )[0].sum :0 :0 }}
                                                </div> 

                                                <div class="tableRows__rateMain-writeOffNumbers"  v-if="( element.events.length && element.events.filter(event => event.type == 'changeTerminalRateId').length && element.events.filter(event => event.type == 'changeTerminalRateId').filter(eventInner => eventInner.details.oldRate.price == rate.price).length ) || ( element.events.length && element.events.filter(event => event.type == 'changeTerminalFolderId').length && element.events.filter(event => event.type == 'changeTerminalFolderId').filter(eventInner => eventInner.details.rate && eventInner.details.rate.price == rate.price).length ) || ( element.events.length && element.events.filter(event => event.type == 'changeTerminalRateId').length && element.events.filter(event => event.type == 'changeTerminalRateId').filter(eventInner => eventInner.details.newRate.price == rate.price).length ) || ( element.events.length && element.events.filter(event => event.type == 'changeTerminalStatus').length && element.events.filter(event => event.type == 'changeTerminalStatus').filter(eventInner => eventInner.details.rate && eventInner.details.rate.price == rate.price).length )">
                                                    <div class="tableRows__rateMain-block" >
                                                        <div class="tableRows__rateMain-number tableRows__rateMain-numberMinus">
                                                            {{ ( -((element.events.length && element.events.filter(event => event.type == 'changeTerminalRateId').length && element.events.filter(event => event.type == 'changeTerminalRateId').filter(eventInner => (eventInner.details.oldRate && eventInner.details.oldRate.price == rate.price)).length)? element.events.filter(event => event.type == 'changeTerminalRateId').filter(eventInner => (eventInner.details.oldRate && eventInner.details.oldRate.price == rate.price && eventInner.details.oldRate.price != eventInner.details.newRate.price)).length : 0 )
                                                             - ((element.events.length && element.events.filter(event => event.type == 'changeTerminalFolderId').length && element.events.filter(event => event.type == 'changeTerminalFolderId').filter(eventInner => (eventInner.details.rate && eventInner.details.rate.price == rate.price) && !invoice.companiesId.includes(eventInner.details.newFolder.id)).length  )? element.events.filter(event => event.type == 'changeTerminalFolderId').filter(eventInner => eventInner.details.rate && eventInner.details.rate.price == rate.price).length : 0)
                                                             -  ((element.events.length && element.events.filter(event => event.type == 'changeTerminalStatus').length && element.events.filter(event => event.type == 'changeTerminalStatus').filter(eventInner => eventInner.details.rate && eventInner.details.rate.price == rate.price).length && element.events.filter(event => event.type == 'changeTerminalStatus').filter(eventInner => eventInner.details.terminal.isEnabled == false).length  )? element.events.filter(event => event.type == 'changeTerminalStatus').filter(eventInner => eventInner.details.terminal.isEnabled == false).length : 0) ) == 0?
                                                                null :
                                                              ( -(element.events.filter(event => event.type == 'changeTerminalRateId').filter(eventInner => (eventInner.details.oldRate && eventInner.details.oldRate.price == rate.price)).length)
                                                             - ( (element.events.filter(event => event.type == 'changeTerminalFolderId').filter(eventInner => eventInner.details.rate && eventInner.details.rate.price == rate.price && !invoice.companiesId.includes(eventInner.details.newFolder.id)).length))
                                                             -  ( element.events.filter(event => event.type == 'changeTerminalStatus').filter(eventInner => eventInner.details.terminal.isEnabled == false).length ) )}}

                                                        </div>
                                                        <div class="tableRows__rateMain-number tableRows__rateMain-numberPlus">
                                                            {{( ((element.events.length && element.events.filter(event => event.type == 'changeTerminalRateId').length && element.events.filter(event => event.type == 'changeTerminalRateId').filter(eventInner => (eventInner.details.newRate && eventInner.details.newRate.price == rate.price)).length)? element.events.filter(event => event.type == 'changeTerminalRateId').filter(eventInner => (eventInner.details.newRate && eventInner.details.newRate.price == rate.price && eventInner.details.oldRate.price != eventInner.details.newRate.price)).length : null) )
                                                             + (((element.events.length && element.events.filter(event => event.type == 'changeTerminalFolderId').length && element.events.filter(event => event.type == 'changeTerminalFolderId').filter(eventInner => eventInner.details.rate && eventInner.details.rate.price == rate.price && invoice.companiesId.includes(eventInner.details.newFolder.id) && !invoice.companiesId.includes(eventInner.details.oldFolder.id) ).length  )? element.events.filter(event => event.type == 'changeTerminalFolderId').filter(eventInner => eventInner.details.rate && eventInner.details.rate.price == rate.price).length : null))
                                                             + ((element.events.length && element.events.filter(event => event.type == 'changeTerminalStatus').length && element.events.filter(event => event.type == 'changeTerminalStatus').filter(eventInner => eventInner.details.rate && eventInner.details.rate.price == rate.price).length && element.events.filter(event => event.type == 'changeTerminalStatus').filter(eventInner => eventInner.details.terminal.isEnabled == true).length  )? element.events.filter(event => event.type == 'changeTerminalStatus').filter(eventInner => eventInner.details.terminal.isEnabled == true).length : null) == 0?
                                                                null : 
                                                            "+"+((element.events.filter(event => event.type == 'changeTerminalRateId').filter(eventInner => (eventInner.details.newRate && eventInner.details.newRate.price == rate.price)).length)
                                                             + (element.events.filter(event => event.type == 'changeTerminalFolderId').filter(eventInner => (eventInner.details.rate && eventInner.details.rate.price == rate.price) && invoice.companiesId.includes(eventInner.details.newFolder.id) && !invoice.companiesId.includes(eventInner.details.oldFolder.id)).length )
                                                             + ( element.events.filter(event => event.type == 'changeTerminalStatus').filter(eventInner => eventInner.details.terminal.isEnabled == true).length ))}}

                                                        </div>                                                        
                                                    </div>

                                                    
                                                </div>                                         
                                            </div>

                                        </td>
                                        <td class="tableRows__rateMain-terminals" @click="openInfoTerminals(element)">
                                            <div>
                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                    width="1280.000000pt" height="1280.000000pt" viewBox="0 0 1280.000000 1280.000000"
                                                    >
                                                    <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
                                                    fill="#353535cc" stroke="none">
                                                    <path d="M6095 12794 c-1453 -77 -2788 -613 -3885 -1558 -138 -119 -447 -423 -570 -561 -517 -579 -915 -1221 -1194 -1928 -224 -567 -361
                                                        -1145 -423 -1792 -24 -252 -24 -858 0 -1110 108 -1120 456 -2103 1068 -3016 355 -531 836 -1049 1344 -1450 989 -780 2125 -1232 3410 -1356 252 -24 858 -24 1110 0 1113 107 2103 456 3004 1059 338 227 614 452 919 753 264 259
                                                        421 437 632 717 728 964 1148 2057 1267 3293 24 252 24 858 0 1110 -137 1422 -679 2669 -1617 3720 -123 138 -432 442 -570 561 -1031 889 -2265 1413 -3625 1539 -162 15 -716 27 -870 19z m525 -2385 c615 -61 1156 -302 1535 -683
                                                        246 -247 428 -573 514 -921 44 -178 56 -297 56 -575 0 -346 -31 -567 -111 -806 -138 -412 -413 -725 -835 -948 -218 -115 -423 -185 -771 -261 l-216 -48 -4 -311 c-5 -337 -8 -357 -68 -476 -51 -101 -155 -187 -281 -231 -94 -34 -285
                                                        -34 -384 -1 -170 57 -271 167 -322 352 -16 59 -18 117 -18 625 0 501 2 566 17 615 37 119 105 148 548 239 310 64 467 118 633 217 331 199 516 500 546 888 26 342 -74 602 -328 857 -112 112 -179 161 -301 220 -168 81 -318 113 -545 113 -309 1 -529
                                                        -57 -735 -192 -112 -74 -296 -258 -447 -447 -138 -172 -284 -318 -347 -346 -58 -25 -202 -39 -286 -28 -94 13 -164 51 -246 133 -123 123 -162 238 -151 449 17 311 118 549 341 800 246 275 590 494 996 631 406 137 777 178 1210 135z m-255 -6980 c67
                                                        -13 178 -69 246 -123 68 -55 124 -126 178 -226 184 -342 4 -770 -379 -901 -99 -34 -258 -32 -361 3 -109 38 -194 91 -275 172 -127 127 -181 278 -171 475 8 176 66 299 198 426 170 162 345 216 564 174z"/>
                                                    </g>
                                                </svg>                                                
                                            </div>

                                        </td>
                                    </tr>
                                    <!-- это строка для  вложенной таблицы , которая появляется по клику на строку (если есть данные) -->
                                    <tr class="tableRows__tr tableRows__innerBlock" v-if="element.visibleInfoBlock">

                                        <td class="tableRows__rateMain" :colspan="(dataHeader.length+3)" v-if="(element.events && element.events.length)" >
                                          
                                            <table class="table-hideInfo">
                                                <tr class="table-hideInfo__tr" v-for="(event, id) in element.events" :key="id">
                                                    <td class="table-hideInfo__timeMain" width="200"> 
                                                        <div style="width:200px">{{(event.type == 'changeTerminalRateId')? 'Смена тарифа' : (event.type == 'changeTerminalFolderId' && !(invoice.companiesId.includes(event.details.newFolder.id) && invoice.companiesId.includes(event.details.oldFolder.id))  )? ' Смена компании': (event.type == 'changeTerminalStatus')? 'Активность терминалов': null}}</div>
                                                    </td>
                                                    <td class="table-hideInfo__timeMain"  width="220" >
                                                        <div style="width:220px"> </div>
                                                    </td>
                                                    <!-- отрисовка названий в скрытом блоке -->
                                                    <td class="table-hideInfo__td" v-for="(rate, n) in dataHeader" :key="n" :style="{'width':(100/(dataHeader.length))+'%'}" >
                                                        <div class="changeTerminalRateId" v-if="event.type == 'changeTerminalRateId'"
                                                            :class="{
                                                                'changeTerminalRateId__plus': event.details.newRate.price == rate.price,
                                                                'changeTerminalRateId__minus': event.details.oldRate.price == rate.price
                                                            }">
                                                            {{(event.details.oldRate.price == rate.price)? event.details.terminal.name + ' убран' : (event.details.newRate.price == rate.price)? event.details.terminal.name + ' добавлен' : null}}
                                                        </div>
                                                        <div class="changeTerminalFolderId" v-if="event.type == 'changeTerminalFolderId' && !(invoice.companiesId.includes(event.details.newFolder.id) && invoice.companiesId.includes(event.details.oldFolder.id))"
                                                            :class="{
                                                                'changeTerminalFolderId__plus': (event.details.rate.price == rate.price && invoice.companiesId.includes(event.details.newFolder.id) && !invoice.companiesId.includes(event.details.oldFolder.id) ),
                                                                'changeTerminalFolderId__minus': (event.details.rate.price == rate.price && !invoice.companiesId.includes(event.details.newFolder.id))
                                                            }">
                                                            {{(event.details.rate.price == rate.price && !invoice.companiesId.includes(event.details.newFolder.id))?event.details.terminal.name +' перемещен в '+  event.details.newFolder.name: (event.details.rate.price == rate.price && invoice.companiesId.includes(event.details.newFolder.id) && !invoice.companiesId.includes(event.details.oldFolder.id) )?    event.details.terminal.name +' перемещен из '+  event.details.oldFolder.name: null}}
                                                        </div>
                                                        <div class="changeTerminalStatus" v-if="(event.type == 'changeTerminalStatus')"
                                                            :class="{
                                                                'changeTerminalStatus__plus': (event.details.rate.price == rate.price && event.details.terminal.isEnabled),
                                                                'changeTerminalStatus__minus': (event.details.rate.price == rate.price && !event.details.terminal.isEnabled)
                                                            }">
                                                            {{(event.details.rate.price == rate.price && !event.details.terminal.isEnabled)? event.details.terminal.name +' неактивный ' : (event.details.rate.price == rate.price && event.details.terminal.isEnabled)?  event.details.terminal.name +' активный ': null }}
                                                        </div>                                                            
                                                    </td>
                                                    <!-- вместо иконки (статика) -->
                                                    <td class="table-hideInfo__emptyTd" width="30">
                                                        <div style="width: 30px"></div>
                                                    </td>
                                                </tr>
                                            </table>

                                        </td>
                                                                                  
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>                  
                </td>
            </tr>
        </tbody>
        <tfoot class="writeOffRates__tfoot">
            <tr class="writeOffRates__tfoot-tr">
                <td class="tableRows__timeMain writeOffRates__tfoot-td" width="200">
                    <div style="width:200px">Итого зачислено</div>
                </td>
                <td class="writeOffRates__tfoot-td topap topup" :colspan="(dataHeader.length+1)">
                    {{$store.state.writeOff.totalFooterPLUS}}                
                </td>
                <td></td>
            </tr>
            <tr class="writeOffRates__tfoot-tr">
                <td class="tableRows__timeMain writeOffRates__tfoot-td" width="200">
                    <div style="width:200px">Итого списано</div>
                </td>
                <td class="tableRows__timeMain " width="220" >
                </td>
                <td class="writeOffRates__tfoot-td  payment" v-for="(rate, idx) in dataHeader" :key="idx"  :style="{'width':(100/(dataHeader.length))+'%'}">
                    {{$store.state.writeOff.totalFooterMINUS[idx]}}                
                </td>
                <td></td>
            </tr>
            <tr class="writeOffRates__tfoot-tr writeOffRates__tfoot-allRates">
                <td class="tableRows__timeMain writeOffRates__tfoot-td" width="200">
                    <div style="width:200px">Итого списано по тарифам</div>
                </td>
                <td class="writeOffRates__tfoot-td" :colspan="(dataHeader.length+1) ">
                    {{$store.state.writeOff.totalFooter}}                
                </td>
                <td></td>
            </tr>
            
        </tfoot>
    </table>

</template>
<script>

import { ref, onMounted } from 'vue'
    export default {
    components: {
    },
    props:{
        dataTable:{
            type: Array,
        },
        dataTotalReport:{
            type: Array,
        }
    },
    setup(props, {emit}){

        const openBlock = (row)=>{
            if(row.events && row.events.length)
             row.visibleInfoBlock = !row.visibleInfoBlock
        }

        const openInfoTerminals = (day)=>{
            emit('openInfoTerminals',day)
        }

        const dataHeader = ref(null)
        const dataBody = ref(null)

        onMounted(()=>{
            dataHeader.value = props.dataTable.header 
            dataBody.value =  props.dataTable.body 
        })

        return{
            dataHeader,
            dataBody,

            openBlock,
            openInfoTerminals
        }
    }

  }
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';

table{
    width: 100%;
    border-collapse: collapse;
    & td{
        transition: all .3s ease;
        border-left: 1px solid $rowactive;
        border-top: 1px solid $rowactive;
    }
}
.changeTerminalFolderId, .changeTerminalRateId, .changeTerminalStatus{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 0 4px;
}
.changeTerminalFolderId__plus, .changeTerminalRateId__plus, .changeTerminalStatus__plus{
    background-color: #00800045;
}
.changeTerminalFolderId__minus, .changeTerminalRateId__minus, .changeTerminalStatus__minus{
    background-color: #ff00003d;
}
.table-hideInfo{
    &__td{
        & > div{
            min-width: 215px;
        }
    }
    &__emptyTd{
        border-left: none !important;
    }
}
.writeOffRates{
    width: 100%;
    min-width: 920px;
    overflow-x: auto;
    border: 1px solid $buttonColor;
    border-collapse: collapse;
    & caption{
        margin-bottom: 15px;
        @include fontStyleTitle;
    }
    &__tr{
        border-bottom: 1px solid #dee2e6;
    }
    &__th, &__td{
        text-align: center;
    }
    &__thTerminals{
        width: 30px;
        height: 30px;
        min-width: 30px;
        line-height: 12px;
        cursor: pointer;
        & > div{
            width: 30px;
            padding: 5px;
        }
        & svg{
            width: 100%;
            height: 100%;
        }
    }
    &__thead-main{
        position: sticky;
        top: -17px;
        z-index: 1;
        & > tr{
            background-color: $buttonColor;
            color: #fff;
        }        
    }
    &__tbody-title{
        font-weight: 700;
        padding: 5px;
        background: #353535cc;
        color: white;
    }
    &__tfoot .topup{
        color: #08c708;
    }
    &__tfoot .payment{
        color: #ff4500;
    }
    &__tfoot td{
        background-color: #f5f5f5;
        border-top: 1px solid #353535cc
    }
    &__tfoot-td{
        font-weight: 700;
        text-align: center;
        // padding: 0.5rem 0.5rem;
        & > div{
            padding: 0.5rem 0.5rem; 
        }
    }
    &__tfoot-allRates{
        font-weight: 700;
        padding: 5px;
        & td{
            background: #353535cc;
            color: white;
        }
        & td:last-child{
            border-left:none;
        }
    }
}
.writeOffRates-innerTable{
    width: 100%;
    border-collapse: collapse;
    &__tr{
        border-bottom: 1px solid #dee2e6;
        &:hover{
            background-color: $rowHover!important;
            cursor: pointer;
        }
    }
    &__th, &__td{
        text-align: center;
    }
}
.tableRows{
    &__innerBlock{
        border: 1px solid $rowHover;
    }
    &__timeMain{
        text-align: center;
        & > div{
            padding: 0.5rem 0.5rem;
            border-left: 1px solid $rowactive;
        }
    }
    &__rateMain{
        position: relative;
        width: calc(100% - 200px);
        min-width: 215px;
        &-info--refill, &-info--refillEmpty{
            font-weight: 700;
            background-color: #08c708;
        }
        &-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid $rowactive;
            min-width: 215px;
            padding: 0.5rem 0.5rem;
        }
        &-writeOffNumbers{
            position: absolute;
            display: flex;
            right: 10px;
            font-size: 14px;
        }
        &-number{
            height: 100%;
        }
        &-numberPlus{
            font-weight: 700;
            color: green;
        }
        &-numberMinus{
            font-weight: 700;
            color: red;
        }
        &-block{
            display: flex;
            grid-gap: 3px;
            padding-top: 2px;
        }
        &-terminals{
            width: 30px;
            height: 30px;  
            min-width: 30px;
            line-height: 12px; 
            cursor: pointer;
            & > div{
                width: 30px;
                padding: 5px;
            }
            & svg{
                width: 100%;
                height: 100%;
            }
            & g{
                transition: all .2s ease;
            }
            &:hover g{
                fill:red;
            }
        }
    }
    
}

</style>