import { useApi } from "@/composition/useApi.js"
export default{
    state: ()=>({
        errorTextRequest: null, // тексты об ошибках
        addingPageIsShow: false,   // открыта ли страница добавления
        allRoles: [],  // все роли
        allPermissionsTypes: [],   // список всех типов прав в системе
        permissionsById: null, // получаем права при редактировании по ID
        editingRole: null, // редактируемая роль
        isLoader: true,   // спиннер
    }),
    mutations:{
        setAllRoles(state, payload){
            state.allRoles = payload
        },
        setAllPermissionsTypes(state, payload){
            payload.forEach(type => {
                // делаем изначально все чекбоксы неактивными
                type.checked = false
                if(!state.permissionsById){
                    type.permissions.forEach(permission => {
                        // смотрим какой должен быть флажок у дочерних элем.
                        if(permission.default)
                            permission.checked = true
                        else
                            permission.checked = false
                    })
                } else {
                    type.permissions.forEach(permission => {
                        if(state.permissionsById.permission.includes(permission.key))
                            permission.checked = true
                        else
                            permission.checked = false
                    })
                }
                // если у дочерних элем. флажки все активны => ставим флажок и родителю
                type.checked = !type.permissions.find(permission => !permission.checked)
            })
            state.allPermissionsTypes = payload
        },
        setPermissionsById(state, payload){
            state.permissionsById = payload
        },
        setAddingPageVisibility(state, bool){
            state.addingPageIsShow = bool
        },
        setRolesLoader(state, bool){
            state.isLoader = bool
        },
        setSelectAllPermissionsOnGroup(state, payload){
            // устанавливаем флажки на всех чекбоксах группы
            const selectedGroup = state.allPermissionsTypes.find(type => type.name == payload.name)
            selectedGroup.checked = payload.bool
            selectedGroup.permissions.forEach(permission => permission.checked = payload.bool)
        },
        setSelectAllPermissions(state, bool){
            // устанавливаем ВСЕ чекбоксы
            state.allPermissionsTypes.forEach(type=>{
                type.checked = bool
                type.permissions.forEach(permission => permission.checked = bool)
            })
        },
        setEditingRole(state, payload){
            state.editingRole = payload
        },
        setSelectOnePermission(state, payload){
            // выбор и снятие одного чекбокса
            const selectedGroup = state.allPermissionsTypes.find(type => type.name == payload.parentNodeName)
            const selectedNode = selectedGroup.permissions.find(permission => permission.key == payload.elemKey)
            // ставим флажок самому элементу
            selectedNode.checked = payload.nodeStatus
            // если у дочерних элем. флажки все активны => ставим флажок и родителю
            selectedGroup.checked = !selectedGroup.permissions.find(permission => !permission.checked)
        },
        // Показ ошибки
        setErrorTextRequest(state, text){
            state.errorTextRequest = text
            setTimeout(()=> state.errorTextRequest = null, 5000)
        },
    },
    actions:{
        // Получить все роли
        getAllRoles({commit}){
            commit('setRolesLoader', true)
            const { api } = useApi();
            return api.get(`/role`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setAllRoles', r.data)
                        commit('setRolesLoader', false)
                    }
                    return r.status
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        // Получить все права в системе
        getAllPermissionsTypes({commit}){
            const { api } = useApi();
            return api.get(`/role/permissions`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setAllPermissionsTypes', r.data)
                    }
                    return r.status
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        // Получить права права id
        getPermissionsById({commit}, id){
            const { api } = useApi();
            return api.get(`/role/${id}`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setPermissionsById', r.data)
                    }
                    return r.status
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        createRole({commit, dispatch}, data){
            const { api } = useApi();
            return api.post(`/role`, data)
                .then((r) => {
                    // обновление страницы после добавления роли
                    dispatch('getAllRoles')
                    // закрываем страницу добавления
                    commit('setAddingPageVisibility', false)
                    return r.status
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        updateRole({commit, dispatch}, data){
            const { api } = useApi();
            return api.put(`/role/${data.id}`, data.body)
                .then(() => {
                    // обновление страницы после добавления роли
                    dispatch('getAllRoles')
                    // закрываем страницу добавления
                    commit('setAddingPageVisibility', false)
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        removeRole({dispatch}, id){
            const { api } = useApi();
            return api.delete(`/role/${id}`)
                .then((r) => {
                    // обновление страницы после добавления роли
                    dispatch('getAllRoles')
                    return r.status
                })
        }
    }
}
