<template>
    <div class="mainHeader">
        <div class="mainHeader__navigation">
          НАСТРОЙКА {{ $store.state.useDefault.titleForHeader }}
        </div>
        <div class="mainHeader__userLogin" @click="openButton">
          {{ userInfo.login }}
          <ul class="mainHeader__userLogin-list" :style="{display: userLogin? 'block': 'none'}">
            <li class="mainHeader__userLogin-item" @click="logoutUser">
              Выйти
            </li>
          </ul>
        </div>
    </div>
  </template>
  
  <script>
  import { useStore } from 'vuex'
  import { computed, ref } from 'vue'
  export default {
    name: 'MainHeader',
    components: {
      
    },
    setup(){
      const userLogin = ref(false)

      const openButton = ()=>{
        userLogin.value = !userLogin.value
      }

      const store = useStore()
      const userInfo = computed(()=> store.state.useApi.userInfo)
      const logoutUser = ()=>{
        store.dispatch('logoutUser')
        localStorage.removeItem('tokenCSRF')
      }
      return{
        userLogin,
        userInfo,
        openButton,
        logoutUser,
      }
    }
  }
  </script>
  <style lang="scss" src="@/assets/styles/layout/header.scss"></style>
  