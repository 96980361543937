<!-- фиксированная обертка окна -->
<template>
    <div class="modal" :style="{zIndex: zIndex}" :class="{ modalFullscreen : isFullscreen}">
        <div class="modal__content" :style="{'width': width? width+'px': 'auto'}">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'modalDefault',
    components: {
    },
    props:{
        scrollHidden:{
            type: Boolean,
            default: true
        },
        width:{
            type: Number
        },
        zIndex: {
            type: Number,
            default: 2
        },
        isFullscreen: {
            type: Boolean,
            default: false
        }
    },
    setup(){
        return{
        }
    }
}
</script>
<style lang="scss" scoped>
.modal{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    transition: opacity 200ms ease-in;
    margin: 0;
    padding: 0;
    pointer-events: auto; 
    opacity: 1; 
    &__content{
        min-width: 500px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: white;
        transform: translate(-50%, -50%);
        border-radius: 0.25rem;
        box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
        padding: 15px 5px;
    }
}
.modalFullscreen{
    position: fixed;
    width: 100vw;
    height: 100vh;
}
</style>