<template>
    
    <table class="table">
        <thead class="table__head">
            <tr>
                <th scope="col">Название</th>
                <th scope="col">Тариф</th>
                <th :style="{width: 130+'px'}" scope="col">Действие</th>
            </tr>
        </thead>
        <tbody class="table__tbody" v-if="$store.state.billing.allInvoices && data">
            <tr class="table__tbody-row" v-for="(data, index) in data" :key="index"
                :data-idTerminal="data.id" 
                :data-idFolder="data.folderId"
                :style="{
                    background: (index%2 == 0)?'#f2f2f2': '#fff'
                }">
                <td class="table__name">{{data.name}}</td>
                <td :style="{width: 106+'px'}">
                    <div class="table__visible">
                        {{data.rateId? ($store.state.billing.allRates.value.filter(item => item.id == data.rateId))[0].price : $store.state.billing.defaultRateCompany}}
                    </div>
                </td>
                <td :style="{width: 130+'px'}">
                    <div class="table__edit">
                        <EditButtonComponent :edit="buttonEdit" @click="rateEdit(data)" />
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

</template>
<script>
import EditButtonComponent from "@/components/UI/EditButtonComponent.vue";
    export default {
    components: {
        EditButtonComponent
    },
    props:{
        data: {
            type: Array,
            required: true
        },
        buttonEdit: {
            type: Boolean,
            default: true
        }    
    },
    setup(props,{emit}){
        const rateEdit = (terminal)=>{
            emit('editRateInvoice',terminal)

        }
        return{
            rateEdit,
        }
    }

  }
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.table{
    width: 100%;
    border-collapse: collapse;
    & tr{
        border-bottom: 1px solid #dee2e6;
    }
    & th, & td{
        padding: 0.5rem 0.5rem;
        text-align: center;
    }
    &__visible{
        border: 1px solid transparent;
    }
    &__select{
        position: relative;
        top: -0.5px;
        left: 12.5px;
    }
}
</style>