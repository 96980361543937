<template>
    <div class="spinner-border" :style="style"></div>
</template>
<script>
import { ref } from 'vue'
export default {
  props:{
    top:{
      type: String,
      required: false,
      default: '47%'
    },
    left:{
      type: String,
      required: false,
      default: '45%'
    },
    width:{
      type: String,
      required: false,
      default: '2rem'
    },
    height:{
      type: String,
      required: false,
      default: '2rem'
    }
  },
  setup(props){
    const style = ref({
      top: props.top,
      left: props.left,
      width: props.width,
      height: props.height,
    })
    return { style }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.spinner-border {
    position: absolute;
    display: inline-block;
    color: $buttonColor;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-left-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg) ;
  }
}

</style>