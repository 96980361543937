<template>
    <ul class="chapters">
      <li class="chapters__item" v-for="(chapter,idx) in chapters" :key="chapter.name"
        @click="sectionActive(chapter,idx)" >
          <div class="chapters__item-title title" 
          :class="{'item--active': chapter.isActiveSection}"
           @mouseenter="activeHoverIcon(chapter)"
           @mouseleave="inactiveHoverIcon(chapter)">
            <div class="chapters__item-icon" >
              <img :src="require(`@/assets/images/sidebar/${(chapter.hoverIcon || chapter.isActiveSection)? chapter.icon[1]: chapter.icon[0]}.svg`)" alt="">
            </div>
            <div class="chapters__item-name">{{chapter.name}}</div>         
          </div>
      </li>
    </ul>
  </template>
  
  <script>
  import config from '/config.json'
  import { useRouter } from "vue-router";

  import { useStore } from 'vuex'
  import { ref, onMounted } from 'vue'
  export default {
    name: 'SidebarList',
    components: {
    },
    setup(){
      const store = useStore()
      const router = useRouter()
      
      const chapters = ref(null)
      const sectionActive = (section)=>{
        section.isActiveSection = !section.isActiveSection
        // активный раздел (редирект)
        router.options.routes.map(route => route.meta.route).includes(section.route) ? router.push(section.path): window.location = `${config.OLD_ADMIN_URL}${section.path}`
        // выделение активного раздела 
        store.commit('setActiveSection', section.route)
      }

      const activeHoverIcon = (el)=>{
        setTimeout(()=>{el.hoverIcon = true},60)
      }
      const inactiveHoverIcon = (el)=>{
        setTimeout(()=>{el.hoverIcon = false},60)
      }

      function activeСhapterOnReboot(){
        if(router.currentRoute.value.meta && router.currentRoute.value.meta.route){
          // активный раздел (редирект)
          router.options.routes.map(route => route.name).includes(router.currentRoute.value.meta.route)? router.push(router.currentRoute.value.path): null
          // выделение активного раздела 
          store.commit('setActiveSection', router.currentRoute.value.meta.route)
        }else{
          setTimeout(()=>{
            activeСhapterOnReboot()
          },100)
        }
      }

      onMounted(()=>{
        // получение всех разделов
        chapters.value = store.state.sidebar.chapters
        // определение активного раздела при перезагрузке
        activeСhapterOnReboot()
      })
      return{
        chapters,

        inactiveHoverIcon,
        activeHoverIcon,

        sectionActive,
        activeСhapterOnReboot,
      }
    }
  }
  </script>
  <style lang="scss" src="@/assets/styles/layout/sidebar.scss"></style>