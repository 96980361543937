import { useApi } from "@/composition/useApi.js"
export default{
    state: ()=>({
        showMessage: false,
        dataCalibration: null
    }),
    mutations:{
        setTransformationCalibration(state,reader){
            let file = atob(reader.result.split(',')[1]);
            file = file.replace(/v2;\d+;/, '').replaceAll('_', ':').replaceAll(',', '.');

            if(!file.match(/^(\d+(\.\d+)*:\d+(\.\d+)*;*)+$/)){
                // показ блока с информацией об ошибке
                state.showMessage = true
                setTimeout(()=> state.showMessage = false,3000 )
                // очищение
                state.dataCalibration = null
                return;
            }
            // преобразование данных под точки
            let calibration = file.split(';');
            calibration = calibration.map(item=> item.split(':') )
            // присвоение
            state.dataCalibration = calibration
        },
        clearDataCalibration(state){
            state.dataCalibration = null
        }
    },
    actions:{
        uploadingRequest(state, data){
            const {api} = useApi()
            data.ids = data.ids.join()
            return api.get(`/invoices/balance/report/xlsx?ids=${data.ids}&from=${data.timestart}&to=${data.timeend}`, { responseType: 'blob' })
                .then((response) => {
                    const blob = response.data
                    const link  = document.createElement('a')
                    // передача атрибутов и запуск передачи
                    link.setAttribute('href', URL.createObjectURL(blob))
                    link.setAttribute('download', 'Отчет списания')
                    link.click()
                    // для сохранения
                    URL.revokeObjectURL(blob) 
                })
        },
        importCalibration({commit},data){
            let  reader = new FileReader()
            reader.addEventListener('load',()=>{
                commit('setTransformationCalibration', reader)
            })
            reader.readAsDataURL(data);
        }
    }
}