<template>
  <div class="mainSidebar">
    <div class="mainSidebar__logo">
      <a class="mainSidebar__logo-link" href="#">
        <img class="mainSidebar__logo-img" src="@/assets/images/icons/logo.2ea98990.svg" alt="">
      </a>
    </div>
    <div class="mainSidebar__list">
      <SidebarList @headerTtitle="headerTtitle" />
    </div>
  </div>
</template>

<script>
import SidebarList from "@/components/layoutComponents/SidebarList.vue"
export default {
  name: 'MainSidebar',
  components: {
    SidebarList
  },
  setup(props, {emit}){
      // получение данных раздела для передачи title в header
      const headerTtitle = (section)=>{
        emit('headerTtitle', section)
      }
      return{
        headerTtitle
      }
  }
}
</script>
<style lang="scss" src="@/assets/styles/layout/sidebar.scss"></style>
