<template>
    
    <table class="table">
        <thead class="table__thead">
            <tr>
                <th scope="col" class="table__thead-name">Название</th>
                <th scope="col">Баланс</th>
                <th scope="col">Email</th>
                <th scope="col">Тариф</th>
                <th scope="col" class="table__thead-action">Действие</th>
            </tr>
        </thead>
        <tbody class="table__tbody" v-if="$store.state.billing.allInvoices">
            <tr v-for="(data, index) in $store.state.billing.allInvoices" :key="index"
                :style="{
                    background: (index%2 == 0)?'#f2f2f2': '#fff'
                }">
                <td>
                    <div class="table__tbody-name" :title= 'data.isBlocked?"Счет заблокирован":"Счет"'>
                        <div class="table__tbody-lock" :style="{opacity: data.isBlocked? 1: 0}" >
                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                width="485.213px" height="485.212px" viewBox="0 0 485.213 485.212" style="enable-background:new 0 0 485.213 485.212;"
                                xml:space="preserve">
                            <g>
                                <path d="M363.908,212.282v-90.978C363.908,54.434,309.509,0,242.606,0c-66.884,0-121.302,54.434-121.302,121.304v90.978
                                    c-33.498,0-60.653,27.158-60.653,60.648v151.629c0,33.5,27.155,60.653,60.653,60.653h242.604c33.491,0,60.653-27.153,60.653-60.653
                                    V272.93C424.562,239.439,397.399,212.282,363.908,212.282z M257.77,359.257v50.139c0,8.382-6.781,15.163-15.163,15.163
                                    c-8.382,0-15.164-6.781-15.164-15.163v-50.139c-8.9-5.269-15.161-14.57-15.161-25.673c0-16.765,13.579-30.327,30.324-30.327
                                    c16.745,0,30.326,13.562,30.326,30.327C272.933,344.687,266.665,353.989,257.77,359.257z M303.255,212.282h-121.3v-90.978
                                    c0-33.465,27.2-60.653,60.651-60.653c33.435,0,60.648,27.188,60.648,60.653V212.282z"/>
                            </g>
                            </svg>
                        </div>
                        <div class="table__tbody-text">
                            {{data.invoiceNumber}}
                        </div>
                    </div>
                </td>
                <td :class="{ 'invoice--plus': data.balance > 0, 'invoice--minus': data.balance < 0}" >
                    {{data.balance}}
                </td>
                <td>{{data.email}}</td>
                <td :style="{width: 106+'px'}">
                    <div class="table__visible" v-if="$store.state.billing.allRates && $store.state.billing.allRates.value.length">
                        {{($store.state.billing.allRates.value.filter(item => item.id == data.defaultRateId))[0].price}}
                    </div>
                </td>
                <td>
                    <div class="table__action">
                        <EditButtonComponent :edit="true" @click="companyEdit(data)" title="Добавить счет" />
                        <EditButtonComponent :edit="false" @click="companyDelete(data)" title="Удалить счет" />
                        <div class="addedBtn" @click="openRefill(data)" title="Пополнить баланс счета">
                            <img :src="require(`@/assets/images/sidebar/${$store.state.sidebar.chapters[6].icon[1]}.svg`)" alt="">
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

</template>
<script>
import EditButtonComponent from "@/components/UI/EditButtonComponent.vue";

  export default {
    components: {
        EditButtonComponent
    },
    props:{
        edit: {
            type: Boolean,
            default: true
        },
    },
    setup(props,{emit}){

        const companyEdit = (company)=>{
            emit('companyEdit', { tab:'edit',active: false }, company)
        }
        const companyDelete = (company)=>{

            emit('companyDelete', company)
        }

        // открыите окна "пополнение счета"
        const openRefill = (data)=>{
            emit('openModalRefill', data)
        }
        return{   
            companyEdit,
            companyDelete,
            openRefill
        }
    }

  }
</script>
<style lang="scss" scoped>
.table{
    width: 100%;
    border-collapse: collapse;
    & tr{
        border-bottom: 1px solid #dee2e6;
    }
    & th, & td{
        padding: 0.5rem 0.5rem;
        text-align: center;
    }

    &__action{
        width: 118px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }
    &__thead {
        th{
            width: 25%;
        }
        &-action{
            width: 150px!important;       
        }
    }
    &__tbody{
        &-name{
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        &-lock{
            width: 20px;
            height: 20px;
            & svg{
                width: 100%;
                height: 100%;
                fill: #E31E24;
            }
        }
        &-text{
            margin-left: 15px;
            text-align: start;
        }        
    }

}
.addedBtn{
    display: flex;
    align-items: center;
    width: 34px;
    height: 34px;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin: 0 auto;
    padding: 0.25rem 0.5rem;
    color: #000;
    background-color: gray;
    border-color: gray;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    cursor: pointer;
    &:hover{
        background-color: #808080b8;
        border-color: #808080b8;
    }

}

</style>