import { useApi } from "@/composition/useApi.js"
export default{
    state: ()=>({
        errorTextRequest: null, // тексты об ошибках
        isPageAddingGroup: false,   // открыта ли страница добавления группы карт
        allGroupCards: [],
        isSpinerGC: true,   // спиннер номер 1
    }),
    mutations:{
        setGroupsCards1(state, payload){
            state.allGroupCards = payload
        },
        setPageAddingGroup(state, bool){
            state.isPageAddingGroup = bool
        },
        setSpinerGroupCards(state, bool){
            state.isSpinerGC = bool
        },
        setErrorTextRequest(state, text){
            state.errorTextRequest = text
            setTimeout(()=> state.errorTextRequest = null, 5000)
        },
    },
    actions:{
        // Все группы карт
        getGroupsCards({commit}){
            commit('setSpinerGroupCards', true)
            const { api } = useApi();
            return api.get(`/cards/groups`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setGroupsCards1', r.data)
                        commit('setSpinerGroupCards', false)
                    }
                    return r.status
                })
        },
        createGroupsCards({commit, dispatch}, data){
            const { api } = useApi();
            return api.post(`/cards/groups`, data)
                .then((r) => {
                    dispatch('getGroupsCards') // обновление страницы после добавления группы карт
                    return r.status
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        updateGroupsCards({commit}, data){
            const { api } = useApi();
            return api.put(`/cards/groups/${data.id}`, data.body)
                .then((r) => r.status)
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        removeGroupsCards({dispatch}, id){
            const { api } = useApi();
            return api.delete(`/cards/groups/${id}`)
                .then((r) => {
                    dispatch('getGroupsCards')  // обновление страницы после добавления группы карт
                    return r.status
                })
        }
    }
}
