<template>
    <div class="writeOff" ref="writeOffBlock">
        <h1 class="writeOff__title">Отчёт операций списания по счетам</h1>
        <div class="writeOff__subTitle">Счета</div>

        <div class="writeOff-wrapper">
            <div class="writeOff__invoices" v-if="$store.state.billing.allInvoices && $store.state.billing.allInvoices.length">
                <div class="writeOff__invoices-checkAll">
                        <div class="writeOff__invoices-checkbox ">
                            <input class="writeOff__invoices-input" type="checkbox" id="checkboxAll" ref="checkboxAll" @click.stop="allCkeck" >
                            <label class="writeOff__invoices-label" for="checkboxAll">Выделить все</label>
                        </div>
                </div>
                <ul class="writeOff__invoices-list" >
                    <li class="writeOff__invoices-item" v-for="invoice in $store.state.billing.allInvoices" :key="invoice.id">
                        <div class="writeOff__invoices-checkbox ">
                            <input class="writeOff__invoices-input" type="checkbox" :data-ivoiceId="invoice.id" :id="invoice.id" ref="checkboxElements" @change="changeInvoice" >
                            <label class="writeOff__invoices-label" :for="invoice.id">{{invoice.invoiceNumber}}</label>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="writeOff__interval" ref="intervalBlock">
                <div class="writeOff__interval-wrap">
                    <div class="writeOff__interval-name">Временной интервал</div>
                    <select class="writeOff__interval-select" :value="selectInput" @change="changeSelect">
                        <option :value="interval.name" v-for="interval in optionsTimeInterval" :key="interval.name"
                            >{{interval.name}}</option>
                    </select>                
                </div>
                <div class="writeOff__datePicker" v-if="isVisibleDatePicker">
                    <div class="writeOff__datePicker-wrap">
                        <div class="writeOff__datePicker-from">
                            С
                        </div>
                        <div class="writeOff__datePicker-dateA">
                            <Datepicker  @open="openDatePicker" @closed ="closedDatePicker"
                                selectText="Выбрать"
                                cancelText="Отмена"
                                class="datePicker-one"
                                placeholder="ДД.ММ.ГГГГ ЧЧ:ММ"
                                v-model="dateA"
                                lang="ru"
                                locale="ru"
                                format="dd.MM.yyyy HH:mm"
                                :time-picker-component="timePicker"
                                textInput
                                monthNameFormat="long"
                            />
                        </div>
                    </div>
                    <div class="writeOff__datePicker-wrap">
                        <div class="writeOff__datePicker-to">
                            ДО
                        </div>
                        <div class="writeOff__datePicker-dateB">
                            <Datepicker @open="openDatePicker" @closed ="closedDatePicker"
                                selectText="Выбрать"
                                cancelText="Отмена"
                                class="datePicker-two"
                                placeholder="ДД.ММ.ГГГГ ЧЧ:ММ"
                                v-model="dateB"
                                lang="ru"
                                locale="ru"
                                format="dd.MM.yyyy HH:mm"
                                :time-picker-component="timePicker"
                                textInput
                                monthNameFormat="long"
                            />
                        </div>
                    </div>
                </div>
                <div class="writeOff__interval-button" >
                    <ButtonComponent @click.stop="submitInvoicesReport">Cформировать отчет</ButtonComponent>   
                    <span class="writeOff__interval-unloadingBtn"><ButtonComponent @click.stop="unloadingReport">Выгрузка отчета</ButtonComponent></span> 
                </div>
                <div class="writeOff__modalError" v-if="$store.state.writeOff.isVisibleErrorModal">
                    <ModalError :infoText="$store.state.writeOff.infoEmptyInvoices"/>
                </div>
            </div>
        </div>
        <div class="writeOff__tableRates" v-if="$store.state.writeOff.isVisibleTable"> 
            <TableWriteOffRates  @openInfoTerminals="openInfoTerminals" :dataTable="$store.state.writeOff.dataInvoicesReport"/>
            <div class="writeOff__tableRates-empty" v-if="$store.state.writeOff.isInfoEmpty">
                 Нет информации по списанию 
            </div>
        </div>
        <div class="writeOff__tableRates-spiner" v-if="$store.state.writeOff.isSpinerReport">
            <SpinnerComponent  />
        </div>
        
        <div class="writeOff__modalTerminals" v-if="isOpenTerminals">
            <ModalDefaultStyles>
                 <ModalWriteOffTerminals :dataPerDay="dataPerDay" @closeModal="closeInfoTerminals" />
            </ModalDefaultStyles>
        </div>
    </div>
</template>
<script>
import SpinnerComponent from "@/components/UI/SpinnerComponent.vue";
import ButtonComponent from "@/components/UI/ButtonComponent.vue";
import ModalDefaultStyles from "@/components/UI/ModalDefaultStyles.vue";
import ModalError from "@/components/modalComponents/ModalError.vue";
import ModalWriteOffTerminals from "@/components/modalComponents/billing/ModalWriteOffTerminals.vue";
import { useStore } from 'vuex';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import TableWriteOffRates from "@/components/billingComponents/TableWriteOffRates.vue";
import { ref, onMounted, computed, defineAsyncComponent, onUnmounted } from 'vue'
const TimePicker = defineAsyncComponent(() => import("@/components/billingComponents/TimePickerCustom.vue"));
export default {
    name:'writeOffComponent',
    components:{
        SpinnerComponent,
        Datepicker,
        ModalError,
        ModalDefaultStyles,
        ModalWriteOffTerminals,
        ButtonComponent,
        TableWriteOffRates
    },
    setup(){
        const store = useStore()
        //datePicker
        const timePicker = computed(() => TimePicker);
        const dateA = ref(null);
        const dateB = ref(null);

        // данные select-option DatePicker
        const optionsTimeInterval = ref(null)
        const selectInput = ref('')

        const isVisibleDatePicker = ref(true)
        
        const intervalBlock = ref()
        const writeOffBlock = ref()

        function startDay(day = new Date()){
            return new Date( new Date(day).setHours(0, 0, 0, 0))
        }
        function endDay(day = new Date()){
            return new Date(new Date(day).setHours(23, 59, 0, 0))
        }

        // получение временного отрезка
        const changeSelect = (event)=>{
            const currentDayWeek = new Date().getDate()
            const previousSunday = currentDayWeek - new Date().getDay()
            const firstDayMonth = new Date().getDate()-(new Date().getDate()-1)
            const lastDayMounth = (new Date().getDate()-(new Date().getDate()-1))- 1
            const thisMounth = new Date().getMonth()
            const previousMonth = new Date().getMonth() - 1

            selectInput.value = event.target.value
            // срабатывает эта ф-ция => после запускается watch(что ниже)
            if(selectInput.value == 'Сегодня'){
                dateA.value = startDay()
                dateB.value = endDay()
            }else if(selectInput.value == 'Вчера'){
                dateA.value = startDay( new Date().setDate(currentDayWeek-1) )
                dateB.value = endDay( new Date().setDate(currentDayWeek-1) )
            }else if(selectInput.value == 'Текущая неделя'){
                dateA.value = startDay( new Date().setDate(previousSunday+1) )
                dateB.value = endDay( new Date().setDate(currentDayWeek) )
            }else if(selectInput.value == 'Предыдущая неделя'){
                dateA.value = startDay( new Date().setDate(previousSunday-6) )
                dateB.value = endDay( new Date().setDate(previousSunday) )
            }else if(selectInput.value == 'Текущий месяц'){
                dateA.value = startDay( new Date().setDate( firstDayMonth) )
                dateB.value = endDay( new Date().setDate( (new Date().getDate( )) ) )
            }else if(selectInput.value == 'Предыдущий месяц'){
                dateA.value = startDay( new Date( new Date().setMonth(previousMonth) ).setDate( firstDayMonth ) )
                dateB.value = endDay( new Date( new Date().setMonth(thisMounth) ).setDate( lastDayMounth ) )   
            }else{
                dateA.value = null;
                dateB.value = null;
            }
            // нужно для дальнейшего сравнения НЕСООТВЕТСТВИЯ диапазона даты и введенного вручную другую дату
            changeTimeRangeA.value = dateA.value
            changeTimeRangeB.value = dateB.value
        }

        const changeTimeRangeA = ref(null)
        const changeTimeRangeB = ref(null)
        // логика работы дат в селекте 'На выбор' ( автомат. отрисовывает актуальное время  )
        const openDatePicker = ()=>{
            if( selectInput.value == 'На выбор' && dateA.value == null ){
                dateA.value = new Date()
                changeTimeRangeA.value = dateA.value
            }
            if( selectInput.value == 'На выбор' && dateB.value == null ){
                dateB.value = new Date()
                changeTimeRangeB.value = dateB.value
            }
        }
        // сравниваем выбранный диапазон дат с изменнением полей(при вводе в ручную)
        const closedDatePicker = ()=>{
            if(dateA.value != changeTimeRangeA.value || dateB.value != changeTimeRangeB.value){
                selectInput.value = 'На выбор'
            }
        }

        // выбрать/убрать все селекты
        const checkboxElements = ref()
        const checkboxAll = ref()
        const allCkeck = ()=>{
            if(checkboxAll.value.checked)
                checkboxElements.value.forEach(checkbox => checkbox.checked = true )
            else
                checkboxElements.value.forEach(checkbox => checkbox.checked = false )
        }
        // активация главного чекбокса при выделении всех компании
        const changeInvoice = ()=>{
            checkboxAll.value.checked = [...document.querySelectorAll('.writeOff__invoices .writeOff__invoices-item .writeOff__invoices-input')].every(checkbox =>checkbox.checked)
        }
        // таблица тарифов
        const isOpenTerminals = ref(false)
        const dataPerDay = ref(null)
        const openInfoTerminals = (day)=>{
            if(day && day.details && day.details.length){
                dataPerDay.value = day
                isOpenTerminals.value = true
                document.querySelector('.billing__page').style.overflow="hidden"
            }else
                isOpenTerminals.value = false 
        }
        const closeInfoTerminals = (bool)=>{
            isOpenTerminals.value = bool
            document.querySelector('.billing__page').style.overflow="auto"
        }
        
        //создание отчета / обновление по нажатию на кнопку
        const dataTotalReport = ref([])
        const dataTotalPLUS = ref([])

        const submitInvoicesReport = ()=>{
            // очистка данных таблицы списания
            store.commit('setDataInvoicesReport', [])
            // очистка [] содержащий общую сумму по тарифам таблицы (footer)
            store.commit('clearTotalFooter')
            dataTotalReport.value = []
            dataTotalPLUS.value = []

            // запуск спинера 
            store.commit('setSpinerReport', true)
            // скрываем таблицу перед сделующим запросом(эффект обновления)
            store.commit('setVisibleTable',false) 
            // убираем доп блок информации об отсутсвии данных(в случае если был выведен)
            store.commit('setInfoEmpty', false)
            // проверка на незаполненное время
            if(!dateA.value){
                dateA.value = new Date(new Date().setHours(0, 0, 0, 0));
            }
            if(!dateB.value){
                dateB.value = new Date(new Date().setHours(23, 59, 0, 0));
            }
            let data = {
                ids:[],
                timestart: Number(dateA.value),
                timeend: Number(dateB.value)
            }
            // сбор выделенных чек-боксов
            document.querySelectorAll('.writeOff__invoices .writeOff__invoices-item .writeOff__invoices-input').forEach(checkbox =>{
                if(checkbox.checked)
                    data.ids.push(checkbox.dataset.ivoiceid)
            })
            //отправка данных на сервер
            if(data.ids.length){
                store.dispatch('getInvoiceReport',data).then(() => {
                    // убираем спинер
                     store.commit('setSpinerReport', false)

                    //отображаем таблицу отчетов списания 
                    store.commit('setVisibleTable',true)
                    // используем setTimeout чтобы отработала сначала эта функция, открыв DOM-элементы для доступа к ним
                    if(store.state.writeOff.dataInvoicesReport.header && store.state.writeOff.dataInvoicesReport.header.length){
                        setTimeout(()=>{
                            // собираю в [] группу одиннаковых чисел (вычисляем общее списание по тарифно) 
                            store.state.writeOff.dataInvoicesReport.header.forEach((rate)=>{
                                document.querySelectorAll('.dataTotal').forEach(block=>{
                                        if(block.dataset.rate == rate.price)
                                            dataTotalReport.value.push(parseFloat(block.innerHTML))          
                                })
                                // эти числа складываю , сохраняю в store , очищаю для новой группы один. чисел 
                                dataTotalReport.value = String(dataTotalReport.value.reduce((sum, el) => sum + el,0).toFixed(2))
                                store.commit('setTotalFooterMINUS', dataTotalReport.value) 
                                dataTotalReport.value = []
                            })
                            //  собираю в [] группу все пополнение 'TOPUP'
                            document.querySelectorAll('.tableRows__rateMain-sum').forEach(toPup=>{
                                dataTotalPLUS.value.push(parseFloat(toPup.dataset.sum))
                            })
                            dataTotalPLUS.value = String(dataTotalPLUS.value.reduce((sum, el) => sum + el,0).toFixed(2))
                            store.commit('setTotalPLUS', dataTotalPLUS.value)
                            store.commit('setTotalFooter') 
                        },0)
                    }else{
                        store.commit('setInfoEmpty', true)
                    }
                    
                 })
            }else{
                // убираем спинер
                store.commit('setSpinerReport', false)
                // вывод информационного окна
                store.commit('setInfoEmptyInvoices', 'Выберите компанию')
                store.commit('setVisibleErrorModal', true)
                setTimeout(()=> store.commit('setVisibleErrorModal', false), 3500)
            }
                
        }
        const unloadingReport = ()=>{
            // проверка на незаполненное время
            if(!dateA.value){
                dateA.value = new Date(new Date().setHours(0, 0, 0, 0));
            }
            if(!dateB.value){
                dateB.value = new Date(new Date().setHours(23, 59, 0, 0));
            }
            // сбор данных
            let data = {
                ids:[],
                timestart: Number(dateA.value),
                timeend: Number(dateB.value)
            }
            document.querySelectorAll('.writeOff__invoices .writeOff__invoices-item .writeOff__invoices-input').forEach(checkbox =>{
                if(checkbox.checked)
                    data.ids.push(checkbox.dataset.ivoiceid)
            })
            // вывод информационного окна если не выбрана ниодна компания
            if(!data.ids.length){
                store.commit('setInfoEmptyInvoices', 'Выберите компанию')
                store.commit('setVisibleErrorModal', true)
                setTimeout(()=> store.commit('setVisibleErrorModal', false), 3500)  
                return
            }
            // запрос и обработка файла
            store.dispatch('uploadingRequest',data )
        }

        onMounted(()=>{
            optionsTimeInterval.value = store.state.writeOff.timeInterval
            //отображает значение "Сегодня" в select при загрузке
            selectInput.value = store.state.writeOff.timeInterval[0].name
            dateA.value = startDay()
            dateB.value = endDay()
            // (для сравнения дат при изменении одного из полей)
            changeTimeRangeA.value = dateA.value
            changeTimeRangeB.value = dateB.value
        })

        onUnmounted(()=>{
            // очистка данных таблицы списания
            store.commit('setDataInvoicesReport', [])
            // очистка [] содержащий общую сумму по тарифам таблицы (footer)
            store.commit('clearTotalFooter')
            dataTotalReport.value = []
            dataTotalPLUS.value = []
            // убираем доп блок информации об отсутсвии данных(в случае если был выведен)
            store.commit('setInfoEmpty', false)
        })
        return{
            openDatePicker,
            closedDatePicker,
            dateA,
            dateB,
            timePicker,

            optionsTimeInterval,

            selectInput,
            changeSelect,

            changeTimeRangeA,
            changeTimeRangeB,
            isVisibleDatePicker,
            intervalBlock,
            writeOffBlock,

            checkboxElements,
            checkboxAll,
            allCkeck,
            changeInvoice,

            isOpenTerminals,
            dataPerDay,
            openInfoTerminals,
            closeInfoTerminals,

            submitInvoicesReport,
            dataTotalReport,
            unloadingReport,
        }
    }
}
</script>
<style lang="scss" src="@/assets/styles/billing/writeOff.scss"> 

</style>