<template>
    <div class="input" v-if="data && data.name && data.name.length">
        <label class="input-label" :for="data.inputName? data.inputName: data.name">{{data.name}}:</label>
        <input class="input-text" :type="inputTypes[data.type] || data.type" ref="inputElement"
            :id="data.inputName? data.inputName: data.name" 
            :placeholder="placeholder? placeholder: data.name" 
            :step="step"
            :min="minValue"
            :required="required"
            :disabled="disabled? disabled: false"
            v-model.trim="valueInput"
            @input="dataTransfer">
    </div>
</template>
  
<script>
import { ref, onMounted } from 'vue'
  export default {
    components: {
    },
    props:{
        data: {
            type: Object,
            default: () => {}
        },
        dataValue:{
            type: [String, Number],
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        editingData: {
            type: Object,
        },
        placeholder: {
            type: String,
        },
        step:{
            type: Number,
            required: false,
            default: 1
        },
        minValue:{
            type: Number,
            required: false,
            default: 0
        }
    },
    emits: [ 'dataTransferValue' ],
    setup(props, {emit}){
            const valueInput = ref(null)
            const inputElement = ref()
            const inputTypes = ref({
                'INT': 'number',
                'FLOAT': 'number',
                'STRING': 'text'
            })

            const dataTransfer = ()=>{ emit('dataTransferValue', valueInput.value, inputElement.value) }

            onMounted(()=>{
                if(props.dataValue || props.dataValue == 0)
                    valueInput.value = props.dataValue
                else
                    valueInput.value = ''
            })
            return{
                valueInput,
                inputElement,
                dataTransfer,
                inputTypes
            }
        }
  }
</script>
<style lang="scss">
@import '@/assets/styles/index.scss';
.input{
    width: 100%;
    &-label{
        flex: 0 0 51%;
        padding-right: 10px;
        cursor: pointer;
    }
    &-text{
        width: 100%;
        margin: .5rem 0;
        padding: 0.375rem 0.75rem;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        outline: none;
        &:focus{
            border-color: $buttonColor;
            box-shadow: 0 0 0 0.25rem rgb(227 30 36 / 25%);
        }
        &.focus{
            border-color: #E31E24;
            box-shadow: inset 0px 0px 10px rgb(227 30 36 / 25%);
        }
    }
    &-checkbox{
        appearance: none;
        width: 1rem;
        height: 1rem;
        border: 1px solid #00000040;
        border-radius: 0.25em;
        background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        &:checked{
            background-color: #E31E24;
        }
        &:hover{
            cursor: pointer;
            border-color: #E31E24!important;
            transform: scale(1.1);
            transition: all .3s ease-in-out;
        }
    }
}
</style>