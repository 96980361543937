import { useApi } from "@/composition/useApi.js"
import { searchTerminalsFuncions } from "@/composition/searchAllTerminals.js"
export default{
    state: ()=>({
        allProtocols: null,

        allFoldersTerminalsFuel: null,
        allTerminalsFuel: null,
        terminalEditing: null,
        terminalsActiveFolder: null,

        activeFolderNameFuel: null,

        //aкьивность кнопки "добавить терминал" на главной
        isPageAddingTerminals: false,
        isAddingTerminalFuel: false,
        //ГРУППЫ КАРТ при добавлении терминала
        groupsCards: null,
        addedGroupsCards: null,
        isVisibleGroupsCards: false,

        selectAllGroupsCards: null,
        selectCheckboxes: [],
        addedGroupCards: [],
        addedSubmitGroupIDS: [],

        // КАРТЫ ДОСТУПА при добавлении терминала
        allFoldersAccessCards: null,
        isVisibleAccessCards: false,
        accessCards: null,

        activeFolderNameAccess: null,

        arrayAddedSubmitIDS: [],
        selectCheckboxesAccess: [],
        addedCheckboxesAccess: [],

        // КАРТЫ ВОДИТЕЛЯ при добавлении терминала
        allFoldersDriversCards: null,
        isVisibleDriversCards: false,
        driversCards: null,

        activeFolderNameDriver: null,

        selectCheckboxDrivers: null,
        addedCheckboxDrivers: null,

        // Переменная, отвечающая за ошибку при удал. терминала
        isErrorDeleteTerminals: false,
        //статические данные Табов
        terminalsTabs: [
            {
                name: 'Топливные терминалы',
                path: '/frame/terminals/fuel',
                active: true
            },
            {
                name: 'Терминалы машин',
                path: '/frame/terminals/car',
                active: false
            }
        ],
        inputTerminalsFuel:[
            {
                name: 'Имя',
                inputName: 'TerminalName',
                type: 'text',
                placeholder:'Имя'
            },
            {
                name: 'IMEI',
                inputName: 'TerminalIMEI',
                type: 'number',
                placeholder:'IMEI'
            },
            {
                name: 'Номер топливной карты',
                inputName: 'TerminalNumber',
                type: 'number',
                placeholder:'Номер топливной карты'
            },
            {
                name: 'Минимальное время стоянки (мин.)',
                inputName: 'ParkingMinDuration',
                type: 'number',
                placeholder:'Минимальное время стоянки (мин.)'
            },
        ],
        inputCheckboxEnabled: {
            name: 'Синхронизация',
            dataCheckbox: 'isEnabled',
            checked: false
        },
        isSpiner2GC: false,   // при загрузке группы карт по id
        allAccessCardsById: null,  // все карты доступа полученные по id
        modalAccessCardsById: false // модалка со списком карт доступа
    }),
    mutations:{
        setActiveTab(state, path){
            //активный
            state.terminalsTabs.forEach( tab => {
                tab.active = false
                if(tab.path == path)
                    tab.active =true
            })
        },
        setProtocols(state, data){
            state.allProtocols = data
            state.allProtocols = state.allProtocols.map((value, idx) => {
                return {'value': value, 'isSelected': idx === 0}
            })
        },
        setSelectedProtocol(state, val){
            state.allProtocols.forEach(itm => {
                itm.isSelected = itm.value === val ? true : false
            })
        },
        setEnabledCheckbox(state, bool){
            state.inputCheckboxEnabled.checked = bool
        },
        setActiveFolderNameFuel(state, folder){
            state.activeFolderNameFuel = folder
        },
        setAllTerminalsFuel(state, data){
            data.forEach(itm => itm.checkedForMove = false) // состояние чекбокса для перемещения в другую папку
            state.allTerminalsFuel = data
        },
        scarletSyncProcesses(state, data){
            state.scarletSyncProcesses = data
        },

        setAllFoldersTerminalsFuel(state, data){
            state.allFoldersTerminalsFuel = data
            // доп обработка для работы поиска (вводит доп поля для отрисовки папок)
            if(state.allFoldersTerminalsFuel){
                // получение данных из файла "поиски для терминалов"
                const { visibleAllFolders } = searchTerminalsFuncions()
                // закрытие всех папок ( закрытие всех внутренних папок , отрисовка всех папок(отрисовывваем все) )
                visibleAllFolders(state.allFoldersTerminalsFuel, false, true)                               
            }

        },

        // ГРУППЫ КАРТ при добавлении терминала
        setGroupsCards(state, data){
            state.groupsCards = data
            // доп обработка. Добавление поля для отрисовки данных(нужно для работы search) , а также отрисовка без учета ранее уже добавленных   
            state.groupsCards = state.groupsCards.filter(card => {card.isVisibleCard = true; return !state.addedSubmitGroupIDS.includes(card.id)})
        },

        setAddedCheckboxes(state, ids){
            if(!state.selectCheckboxes.includes(ids)){
                state.selectCheckboxes.push(ids)
            }   
        },
        setDeleteCheckboxes(state, ids){
            state.selectCheckboxes = state.selectCheckboxes.filter(checkbox => checkbox != ids) 
        },
        setVisibleGroupsCards(state, bool){
            state.isVisibleGroupsCards = bool
        },

        setSelectAllGroupsCards(state, bool){
            if(bool){
                state.groupsCards.forEach(group => {
                    if(!group.checked && group.isVisibleCard){
                        group.checked = bool
                        state.selectCheckboxes.push(group.id)
                    }
                })
            }else{
                state.groupsCards.forEach(group => {
                    if(group.isVisibleCard){
                        group.checked = bool
                        state.selectCheckboxes = state.selectCheckboxes.filter(checkbox => checkbox != group.id)
                    }
                })
            }
        },
        clearSelectGroup(state){
            if(state.groupsCards && state.groupsCards.length)
                state.groupsCards.forEach(group => group.checked = false )

            state.selectCheckboxes= []
        },
        clearAddedGroupCards(state){
            state.addedGroupCards = []
        },
        clearAddedSubmitGroupIDS(state){
            state.addedSubmitGroupIDS = []
        },

        setSubmitGroupCards(state){
            if(state.addedGroupCards && !state.addedGroupCards.length)
                state.addedGroupCards = state.groupsCards.filter(group => { group.isVisibleCard = true; return state.selectCheckboxes.includes(group.id)})
            else
                state.addedGroupCards = [...state.addedGroupCards, ...state.groupsCards.filter(group => {group.isVisibleCard = true; return state.selectCheckboxes.includes(group.id)})]
            // собираем id-ники для исключения добавленных их при отрисовки
            state.addedSubmitGroupIDS = state.addedGroupCards.map(card => card.id)
        },
        deleteSubmitGroupCards(state, groupCard){
            state.addedGroupCards = state.addedGroupCards.filter( card => card.id != groupCard.id)
            // собираем id-ники для исключения добавленных при отрисовки
            state.addedSubmitGroupIDS = state.addedGroupCards.map(card => card.id)
            // отрисовка оставшихся данных
            state.addedGroupCards.forEach(item => item.isVisibleCard = true)
        },

        // КАРТЫ ДОСТУПА
        setAllFoldersAccessCards(state, data){
            state.allFoldersAccessCards = data
            // доп обработка для работы поиска (вводит доп поля для открисовки папок)
            if(state.allFoldersAccessCards){
                // получение данных из файла "поиски для терминалов"
                const { visibleAllFolders } = searchTerminalsFuncions()
                // закрытие всех папок ( закрытие всех внутренних папок , отрисовка всех папок(отрисовывваем все) )
                visibleAllFolders(state.allFoldersAccessCards, false, true)           
            }    
        },
        setVisibleAccessCards(state, bool){
            state.isVisibleAccessCards = bool
        },
        setAccessCards(state, data){
            state.accessCards = data 
            // доп обработка. Добавление поля для отрисовки данных(нужно для работы search) , а также отрисовка без учета ранее уже добавленных  
            state.accessCards = state.accessCards.filter(card => { card.isVisibleTerminal = true; return !state.arrayAddedSubmitIDS.includes(card.id)})
        },
        setActiveFolderNameAccess(state, folder){
            state.activeFolderNameAccess = folder
        },
        setActiveAccessCards(state){
            state.accessCards.forEach(card => {
                state.selectCheckboxesAccess.forEach(selectCard =>{
                    if(selectCard.id == card.id)
                        card.checked = true
                })
            })
        },
        setSelectAllAccessCards(state, bool){
            if(bool){
                state.accessCards.forEach(group => {
                    if(!group.checked && group.isVisibleTerminal){
                        group.checked = bool
                        state.selectCheckboxesAccess.push(group)  
                    }
                })
            }else{
                state.accessCards.forEach(group => {
                    if(group.isVisibleTerminal){
                        group.checked = bool
                        state.selectCheckboxesAccess = state.selectCheckboxesAccess.filter(checkbox => checkbox.id != group.id)
                    }
                })
            }
        },
        setSelectCheckboxesAccess(state, accessCards){
            state.selectCheckboxesAccess.push(accessCards)
        },
        setAllSelectChecboxes(state, data){
            state.selectCheckboxesAccess = data
        },

        setDeleteCheckboxesAccess(state, accessCards){
            state.selectCheckboxesAccess = state.selectCheckboxesAccess.filter(card => card.id != accessCards.id) 
        },
        clearAccessArray(state){
            if(state.accessCards && state.accessCards.length)
                state.accessCards.forEach(group => group.checked = false )

            state.selectCheckboxesAccess = []
        },
        clearAddedCheckboxesAccess(state){
            state.addedCheckboxesAccess = []
        },

        setSubmitAccessCards(state){
            // добавляем данные в общую таблицу , с проверкой на ранее добавленные
            if(!state.addedCheckboxesAccess.length){
                // переносим выбранные чекбоксы в добавленные
                state.addedCheckboxesAccess = state.selectCheckboxesAccess
            }else
                state.addedCheckboxesAccess = [...state.addedCheckboxesAccess, ...state.selectCheckboxesAccess]

            // добавляем поле для отрисовки в списке
            state.addedCheckboxesAccess.forEach( item => item.isVisibleCard = true)
            // собираем id-ники для исключения добавленных при отрисовки
            state.arrayAddedSubmitIDS = state.addedCheckboxesAccess.map(card => card.id)

            // обновляем выделенные селекторы , т к их добавили 
            state.selectCheckboxesAccess = []
        },
        setAddedCheckboxesAccess(state, data){
            data.forEach(item => item.isVisibleCard = true)
            state.addedCheckboxesAccess = data
        },
        deleteSubmitAccessCards(state, accessCard){
            state.addedCheckboxesAccess = state.addedCheckboxesAccess.filter( card => card.id != accessCard.id)
            // собираем id-ники для исключения добавленных при отрисовки
            state.arrayAddedSubmitIDS = state.addedCheckboxesAccess.map(card => card.id)
            // отрисовка оставшихся данных
            state.addedCheckboxesAccess.forEach(item => item.isVisibleCard = true)
        },

        // КАРТЫ ВОДИТЕЛЯ
        setDriversCards(state, data){
            state.driversCards = data
             // доп обработка. Добавление поля для отрисовки данных(нужно для работы search)
            state.driversCards.forEach( card => card.isVisibleCard = true )
        },
        setAllFoldersDriversCards(state, data){
            state.allFoldersDriversCards = data
            // доп обработка для работы поиска (вводит доп поля для открисовки папок)
            if(state.allFoldersDriversCards){
                // получение данных из файла "поиски для терминалов"
                const { visibleAllFolders } = searchTerminalsFuncions()
                // закрытие всех папок ( закрытие всех внутренних папок , отрисовка всех папок(отрисовывваем все) )
                visibleAllFolders(state.allFoldersDriversCards, false, true)               
            }            
        },
        setVisibleDriversCards(state, bool){
            state.isVisibleDriversCards = bool
        },

        setActiveFolderNameDriver(state, folder){
            state.activeFolderNameDriver = folder
        },

        setActiveDriverCards(state){
            state.driversCards.forEach(card => { 
                if(card.id == state.selectCheckboxDrivers.id)
                    card.checked = true
            })
        },

        clearVisibleCheckboxesDrivers(state){
            state.driversCards.forEach(card => {
                card.checked = false
            })
        },
        setSelectCheckboxDrivers(state, driverCard){
            state.selectCheckboxDrivers = driverCard
        },
        setDeleteCheckboxesDrivers(state){
            state.selectCheckboxDrivers = null
        },
        
        setSubmitDriversCards(state){
            state.addedCheckboxDrivers = state.selectCheckboxDrivers
        },
        clearSubmitDriversCards(state){
            state.addedCheckboxDrivers = null
        },

        // ИЗМЕНЕНИЕ ТЕРМИНАЛОВ
        setPageAddingTerminals(state, bool){
            state.isPageAddingTerminals = bool
        },
        setAddingTerminalFuel(state, bool){
            state.isAddingTerminalFuel = bool
        },
        setTerminalEditing(state, terminal){
            state.terminalEditing = terminal
        },
        setSpiner2GroupCards(state, bool){
            state.isSpiner2GC = bool
        },
        setAllAccessCardsById(state, data){
            state.allAccessCardsById = data
        },
        setModalAccessCardsById(state, bool){
            state.modalAccessCardsById = bool
        },
        // Перемещение терминалов из папки в папку
        selectOneFuelCheckboxForMove(state, payload){
            // выбор одного терминала для перемещения
            state.allTerminalsFuel.find(itm => itm.id === payload.id).checkedForMove = payload.bool
        },
        selectAllFuelCheckboxesForMove(state, bool){
            // выбор всех терминалов для перемещения
            state.allTerminalsFuel.forEach(itm => {
                itm.checkedForMove = bool
            })
        },
        removeTerminalFuel(state, arr){
            // удаление термингала из папки после перемещения
            arr.forEach(itm => {
                state.allTerminalsFuel = state.allTerminalsFuel.filter(terminal => terminal.id !== itm)
            })
        },
        // ОШИБКА ПРИ УДАЛЕНИИ ТЕРМИНАЛА на главное странице
        errorDeleteTerminalsFuel(state, bool){
            state.isErrorDeleteTerminals = bool
            setTimeout(()=> state.isErrorDeleteTerminals = false, 3000)
        }
 
    },
    actions:{
        getAllTerminalsFuel({commit}){
            const { api } = useApi();
            return api.get(`/terminals/fuel`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setAllTerminalsFuel', r.data)
                })
        },
        getScarletSyncProcesses({commit}){
            const { api } = useApi();
            return api.get(`/scarlet/sync/processes`)
                .then((r) => {
                    if(r.status == 200)
                        commit('scarletSyncProcesses', r.data)
                })
        },
        getTerminalsFuelFolder({commit}, id){
            const { api } = useApi();
            return api.get(`/terminals/folder/${id}?type=fuel`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setAllTerminalsFuel', r.data)
                })
        },
        getGroupsCards({commit}){
            // запрос на получение всех групп карт
            const { api } = useApi();
            return api.get(`/cards/groups`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setGroupsCards', r.data)
                })
        },
        getAccessCards({commit},id){
            // запрос на получение карт по id группы карт
            const { api } = useApi();
            return api.get(`/cards/folder/${id}`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setAccessCards', r.data)
                })
        },
        getTerminalAccessCards({commit},id){
            // запрос на получение карт которые относятся к терминалу
            const { api } = useApi();
            return api.get(`/terminals/${id}/selected-cards`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setAddedCheckboxesAccess', r.data)
                    return r
                })
        },
        getTerminalGroupCards({commit, state},id){
            // запрос на получение группы карт которые относятся к терминалу
            const { api } = useApi();
            return api.get(`/terminals/${id}/selected-card-groups`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setGroupsCards', r.data)
                        state.selectCheckboxes = r.data.map(itm => itm.id)
                        commit('setSubmitGroupCards')
                    }
                    return r
                })
        },
        getGroupCardsById({commit},id){
            commit('setSpiner2GroupCards', true)
            const { api } = useApi();
            return api.get(`/cards/groups/${id}/cards`)
                .then((r) => {
                    if(r.status == 200) {
                        commit('setAccessCards', r.data)
                        commit('setAddedCheckboxesAccess', r.data)
                        commit('setSpiner2GroupCards', false)
                    }
                })
        },
        getAllAccessCardsById({commit},id){
            commit('setModalAccessCardsById', true)
            commit('setSpiner2GroupCards', true)
            const { api } = useApi();
            return api.get(`/terminals/${id}/cards`)
                .then((r) => {
                    if(r.status == 200){
                        r.data.forEach(card => card.isVisibleCard = true) // добавляем флаг отвечающий за видимость карточек в поиске
                        commit('setAllAccessCardsById', r.data)
                        commit('setSpiner2GroupCards', false)
                    }
                })
        },
        getDriversCards({commit},id){
            const { api } = useApi();
            return api.get(`/cards/folder/${id}`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setDriversCards', r.data)
                })
        },
        getAllProtocols({commit}){
            const { api } = useApi();
            return api.get(`/terminals/protocols`)
                .then((r) => {
                    if(r.status == 200){
                        let result = r.data.map(item => item.toLowerCase())
                        commit('setProtocols', result)
                    }
                })
        },
        getTerminal({commit}, id){
            const { api } = useApi();
            return api.get(`/terminals/${id}`)
                .then((r) => {
                    commit('setTerminalEditing', r.data)
                })
        },

        editingCreatedTerminal(state, data){
            const { api } = useApi();
            return api.put(`/terminals/${data.id}`, data.body)
        },
        submitCreateTerminal(state, body){
            const { api } = useApi();
            return api.post(`/terminals`, body)
        },
        changeFuelTerminalFolder(state, payload){
            const { api } = useApi();
            return api.patch(`/terminals/folder`, payload)
        },
    }
}