<template>
    <div class="refill">
        <div class="refill-wrap">
            <div class="refill__info" >
                Счет: <span>{{data.invoiceNumber}}</span>
            </div>
            <div class="refill__close" @click="closeModal">
                <ButtonComponent>Закрыть</ButtonComponent>
            </div>
        </div>
        <form class="refill__form" @submit.prevent="submitRefill">
            <div class="refill__title" >
                Пополнить баланс
            </div>
            <div class="refill-content">
                <div class="refill__balance">
                    <InputComponent :data="dataInput[0]" :required='true' />
                </div>
                <div class="refill__description">
                    <div class="refill__description-wrap">
                        <InputComponent :data="dataInput[1]" :required='true' />
                    </div>
                </div>
            </div>
            <div class="refill__button">
                <ButtonComponent type="submit">Пополнить</ButtonComponent>
            </div>            
        </form>

    </div>
</template>
<script>
import InputComponent from "@/components/UI/InputComponent.vue";
import ButtonComponent from "@/components/UI/ButtonComponent.vue";
import { useStore } from 'vuex';
import { ref } from "vue";
export default {
    name: 'modalReffil',
    components:{
        ButtonComponent,
        InputComponent
    },
    props:{
        data:{
            type: Object
        }
    },
    setup(props, {emit}){
        const store = useStore()

        const dataInput = ref([
            {
                name: 'Сумма платежа:',
                inputName: 'invoiceRefill',
                type: 'number',
                placeholder:'Пополнить на сумму'
            },
            {
                name: 'Описание:',
                inputName: 'invoiceDescription',
                type: 'textarea',
                placeholder:'Описание'
            },
        ])
        const submitRefill = ()=>{
            let data = {
                id: props.data.id,
                body:{
                    "sum":parseFloat( (parseFloat(document.querySelector('.refill__balance .input-text').value)).toFixed(2) ),
                    "description": document.querySelector('.refill__description .input-text').value
                }
            }
            store.dispatch('getBalance',data).then(()=>{
                //очищение и закрытие
                document.querySelector('.refill__balance .input-text').value = ''
                document.querySelector('.refill__description .input-text').value = ''
                store.dispatch('getCompaniesFree')
                store.dispatch('getAllInvoices')
                emit('closeModalRefill', false)
            })
        }
        const closeModal = ()=>{
            emit('closeModalRefill', false)
        }

        return{
            dataInput,
            submitRefill,
            closeModal
        }
    }
    
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.refill{
    width: 600px;
    max-height: 600px;
    overflow-y: auto;
    padding: 5px 10px;
    margin-bottom: 15px;
    &__info span{
        @include fontStyleTitle;
        margin-bottom: 0;
    }
    &-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }
    &__title{
        @include fontStyleTitle;
        text-align: center;
        margin-bottom: 15px;
    }
    &-content{
        margin-bottom: 30px;
    }
    &__button{
        text-align: end;
    }
    &__balance{
        width: 100%;
        display: flex;
        align-items: center; 
        margin-bottom: 15px;  
        &-name{
            text-align: center;
        }
        &-select{
            flex: 1 0 auto;
            margin: .5rem 0;
            margin-left: 15px;
            padding: 0.23rem 0.75rem;
            background-color: #fff;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            outline: none;
            &:focus{
                border-color: #E31E24;
                box-shadow: 0 0 0 0.25rem #e31e2440;
            }
        }
        
    }
    &__description{
        width: 100%;
        &-input{
            width: 100%;
            margin: .5rem 0;
            padding: 0.23rem 0.75rem;
            background-color: #fff;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            outline: none;
            &:focus{
                border-color: #E31E24;
                box-shadow: 0 0 0 0.25rem #e31e2440;
            }
        }
    }
}
</style>