import { useApi } from "@/composition/useApi.js"
import { searchTerminalsFuncions } from "@/composition/searchAllTerminals.js"
export default{
    state: ()=>({
        errorTextRequest: null,

        isPageAddingFuelCardServices: false,

        allFoldersFuelCardServices: [],
        activeFolderFuelCardServices: null,

        activeFuelCardServicesForFolder: [],

        isMacrosList: false,

        isSpinerMainFolders: true,
        isSpinerMainList: true,
        // редактирование и удаление
        serviceEditing: null
    }),
    mutations:{
        setErrorTextRequest(state, text){
            state.errorTextRequest = text
            setTimeout(()=> state.errorTextRequest = null, 3000)
        },

        // ГЛАВНАЯ СТРАНИЦА
        setAllFoldersFuelCardServices(state, data){
            state.allFoldersFuelCardServices = data
            // доп обработка для работы поиска (вводит доп поля для открисовки папок)
            if(state.allFoldersFuelCardServices){
                // получение данных из файла "поиски для терминалов"
                const { visibleAllFolders } = searchTerminalsFuncions()
                // закрытие всех папок ( закрытие всех внутренних папок , отрисовка всех папок(отрисовывваем все) )
                visibleAllFolders(state.allFoldersFuelCardServices, false, true)
            }
        },

        setActiveFolderFuelCardServices(state, folder){
            state.activeFolderFuelCardServices = folder
        },

        setFuelCardServicesForFolder(state, fuelCardServices){
            state.activeFuelCardServicesForFolder = fuelCardServices
        },

        // СОЗДАНИЕ / РЕДАКТИРОВАНИЕ
        setPageAddingFuelCardServices(state, bool){
            state.isPageAddingFuelCardServices = bool
        },

        setAllTypes(state, types){
            state.allTypes = types
        },

        setVisibleTypesList(state, bool){
            state.isTypesList = bool
        },
        // АКТИВНОСТЬ СПИННЕРОВ НА ГЛАВНОЙ СТРАНИЦЕ
        setSpinerMainFolders(state, bool){
            state.isSpinerMainFolders = bool
        },
        setSpinerMainList(state, bool){
            state.isSpinerMainList = bool
        },
        // РЕДАКТИРОВАНИЕ и УДАЛЕНИЕ
        setServiceEditing(state, service){
            state.serviceEditing = service
        }
    },
    actions:{
        getFuelCardServicesFolder({commit}, id){
            commit('setSpinerMainList', true)
            const { api } = useApi();
            return api.get(`/fuel-card-services?folderId=${id}`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setFuelCardServicesForFolder', r.data)
                        commit('setSpinerMainList', false)
                    }
                    return r.status
                })
        },
        getTypesService({commit}){
            const { api } = useApi();
            return api.get(`/fuel-card-services/types`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setAllTypes', r.data)
                    return r.status
                })
        },
        createService({commit}, data){
            const { api } = useApi();
            return api.post(`/fuel-card-services`, data)
                .then((r) => r.status)
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        serviceEditing({commit}, data){
            const { api } = useApi();
            return api.put(`/fuel-card-services/${data.id}`, data)
                .then((r) => r.status)
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        serviceDeliting(state, id){
            const { api } = useApi();
            return api.delete(`/fuel-card-services/${id}`)
                .then((r) => r.status)
        }
    }
}
