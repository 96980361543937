import { useApi } from "@/composition/useApi.js"
export default{
    state: ()=>({
        isVisibleErrorCreate: false,
        isModalСonfirmation: false,
        isVisibleInvoices: true,
        isActiveTerminals: false,

        textErrorCreate: '',

        createCompaniesFree:[],
        createTerminalsFree:[],
        
        editCompaniesFree: [],
        editTerminalsFree:[],

        mainCompaniesFree: [],
        mainTerminalsFree:[],

        invoiceEdit: {},  // данные от запроса (данные ред счета - в кот нет  инфы о терминалах)
        invoiceForEdit: null, // локальные изменяющиеся данные редактируемого счета
        editTableTerminal: null, // редактируемый терминал в редактируемом счете
        allModifiedTerminals: [], // все отредактированные терминалы

        editRatesTerminal: [],
        updateInvoices: [],
        allInvoices: [],

        defaultRateCompany: null,
        allModules: [],

        // отсутствие тарифов
        isEmptyRates: false,
        //статические данные Табов
        allRates:{
            name: 'Тариф по умолчанию:',
            value:  []
        },
        billingTabs:[
            {
              tab:'Все счета',
              active: true 
            }, 
            {
              tab:'create',
              active: false 
            }, 
            {
              tab:'edit',
              active: false 
            }, 
            {
              tab:'Отчет списания',
              active: false 
            }
        ],
        inputDataDefault:[
            {
                name: 'Номер счета',
                inputName: 'invoiceNumber',
                type: 'text',
                placeholder:'номер счета'
            },
            {
                name: 'E-mail',
                inputName: 'invoiceEmail',
                type: 'email',
                placeholder:'email'
            },
            {
                name: 'Баланс',
                inputName: 'invoiceBalance',
                type: 'number',
                placeholder:'баланс'
            },  
            {
                name: 'Отправлять извещение за дней до блокировки',
                inputName: 'noticeBeforeBlock',
                type: 'number',
                placeholder:'кол-во дней'
            },
            {
                name: 'Порог блокировки',
                inputName: 'blockingLevel',
                type: 'number',
                placeholder:'баланс'
            }    
        ],
        inputDataCheckbox:[      
            {
                name: 'Блокировать объекты при блокировки счета',
                dataCheckbox: 'isBlockObjects',
                checked: false
            }
            ,            {
                name: 'Блокировать счёт при отрицательном балансе',
                dataCheckbox: 'isBlockInvoice',
                placeholder: '',
                checked: false
            }
            ,            {
                name: 'Показать сумму долга при входе заблокированного пользователя',
                dataCheckbox: 'isShowDebt',
                checked: false
            }
        ],
    }),
    mutations:{
        setTextErrorCreate(state, dataText){
            state.textErrorCreate = dataText
            if(Array.isArray(dataText))
                state.textErrorCreate = dataText.join(', ')
            
        },
        setModalErrorCreate(state){
            state.isVisibleErrorCreate = true
            setTimeout(()=>{state.isVisibleErrorCreate = false},3500)
        },
        setModalСonfirmation(state, bool){
            state.isModalСonfirmation = bool
        },
        setAllModules(state, data){
            state.allModules = data
        },
        setEditRatesTerminal(state, data){
            state.editRatesTerminal.push(data)
            state.editRatesTerminal = state.editRatesTerminal.filter(terminalRates => terminalRates.id != data.id)
            state.editRatesTerminal.push(data)
        },
        setClearRatesTerminal(state){
            state.editRatesTerminal = []
        },

        setVisibleInvoices(state,bool){
            state.isVisibleInvoices = bool
        },

        setDefaultRateCompany(state, data){
            state.defaultRateCompany = data
        },

        setInvoiceForEdit(state,data){
            state.invoiceForEdit = data
        },
        setChangeInvoice(state,data){
            state.allInvoices.forEach(item=>{
                if(item.id == data[0])
                    item = data[1]
            })
        },
        setEditTableTerminal(state, terminal){
            state.editTableTerminal = terminal
        },

        addingModifiedTerminals(state, terminalId){
            if(!state.allModifiedTerminals.includes(terminalId))
                state.allModifiedTerminals.push(terminalId)
        },
        clearModifiedTerminals(state){
            state.allModifiedTerminals = []
        },

        setCreateInvoices(state,data){
            state.allInvoices.push(data)
        },

        setCreateCompaniesFree(state,data){
            state.createCompaniesFree = data
        },
        setCreateTerminalsFree(state,data){
            state.createTerminalsFree = data
        },
        setEditCompaniesFree(state,data){
            state.editCompaniesFree = data
            state.editCompaniesFree = state.editCompaniesFree.sort((a,b)=> state.invoiceEdit.companiesId.includes(b.id) - state.invoiceEdit.companiesId.includes(a.id))
        },
        setEditTerminalsFree(state,data){
            state.editTerminalsFree = data
        },
        clearCreateCompaniesFree(state){
            state.createCompaniesFree = []
            state.createTerminalsFree = []
        },

        setSearchActiveArrow(state, bool){
            state.mainCompaniesFree.forEach(item=> {item.activeArrow = bool})
        },

        setAllInvoices(state, data){
            state.allInvoices = data
        },
        deleteInvoices(state, id){
            state.allInvoices =  state.allInvoices.filter(invoice=> invoice.id != id)
        },

        
        setActiveTerminals(state,bool){
            state.isActiveTerminals = bool
        },
        setMainCompaniesFree(state, data){
            state.mainCompaniesFree = data
        },
        setMainTerminalsFree(state, data){
            state.mainTerminalsFree = data
        },
        clearCompaniesFree(state){
            state.mainCompaniesFree = []
            state.mainTerminalsFree = []
        },
        setDeleteCompaniesFree(state,companiesId){
            state.createCompaniesFree = state.createCompaniesFree.filter(item => item.id != companiesId)
        },
        setUpdateInvoices(state,data){
            state.allInvoices = state.allInvoices.map(item=>{
                if(item.id == data.id)
                    item = data
                return item
            })
        },
        setInvoiceEdit(state, data){  // возможно не нужен будет( т к инфу в реал времени получаем по нажатию ан кнопку)
            state.invoiceEdit = data   // если только там не добавят инфу о терминалах
        },

        setAllRates(state, data){
            state.allRates.value = data
        },

        setBillingTabs(state, selectTab){
            state.billingTabs.forEach(item => {
                item.active = false
                if(selectTab.tab == item.tab)
                    item.active = true
            })
        },

        setEmptyRates(state, bool){
            state.isEmptyRates = bool
        }
    },
    actions:{
        createInvoices({commit},body){
            const { api } = useApi();
            return api.post(`/invoices`, body)
                .then((r) => {
                    if(r.status == 201)
                        commit('setCreateInvoices',r.data) 
                    return r.status 
                })
                .catch((r)=>{
                    if(r.response.data.message == "Companies not found")
                        commit('setTextErrorCreate', 'Не выбрана ни одна компания')
                    else
                        commit('setTextErrorCreate', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)

                    commit('setModalErrorCreate')
                    
                })
        },

        getAllInvoices({commit}){
            const { api } = useApi();
            return api.get(`/invoices`)
                .then((r) => {         
                    if(r.status==200){  
                        if(!r.data.length)
                            commit('setVisibleInvoices',false)  
                            commit('setAllInvoices',r.data)   
                    }
                })
        },
        getAllRates({state,commit}){
            commit('setEmptyRates', false)

            const { api } = useApi();
            return api.get(`/rates`)
                .then((r) => {
                    if(r.status==200){
                        if(!r.data.length)
                            return commit('setEmptyRates', true)

                        commit('setAllRates',r.data)   

                        if(!state.defaultRateCompany)
                            commit('setDefaultRateCompany', r.data[0].price) //сохраняем дефолтное значение для отрисовки тарифа у терминалов со значением null              
                    }
                })
        },
        getCompaniesFree({commit}){
            const { api } = useApi();
            return api.get(`/invoices/companies/free?includeTerminals=true`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setCreateCompaniesFree',r.data.companies)
                        commit('setCreateTerminalsFree',r.data.terminals) 
                    }
                })
        },
        getAllModules({commit}){
            const { api } = useApi();
            return api.get(`/modules`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setAllModules',r.data)   
                })
        },
        getDeleteInvoice({commit},id){
            const { api } = useApi();
            return api.delete(`/invoices/${id}`)
                .then((r) => {
                    if(r.status == 204){
                        commit('deleteInvoices',id)   
                    }
                })
        },
        getEditInvoice({commit},id){
            const { api } = useApi();
            return api.get(`/invoices/${id}`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setInvoiceEdit',r.data)   
                })
        },
        getEditTerminals({state,commit},companiesId){
            const { api } = useApi();
            return api.get(`/invoices/companies/free?includeTerminals=true&compForUpd=${companiesId.join()}`)
                .then((r) => {
                    if(r.status == 200){       
                        commit('setEditCompaniesFree',r.data.companies)
                        commit('setEditTerminalsFree',r.data.terminals)
                        //изменение основных данных для отрисовки 
                        commit('setMainCompaniesFree',state.editCompaniesFree)
                        commit('setMainTerminalsFree',state.editTerminalsFree)
                    }
                })
        },
        changeInvoice({commit},body){
            const { api } = useApi();
            return api.put(`/invoices/${body.id}`, body)
                .then((r) => {
                    if(r.status == 204)
                        commit('setInvoiceForEdit',r.data)  // используется локально  (отрисовывает все , кроме терминалов -они по rateID)
                    return r.status
                })
                .catch((r)=>{
                    commit('setTextErrorCreate', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                    commit('setModalErrorCreate')
                })
        },
        getBalance(state,data){
            const { api } = useApi();
            return api.patch(`/invoices/balance/${data.id}`, data.body)
        },
    }
}
