import { createStore } from 'vuex'
//общие данные
import useApi from '@/store/useApi.js'
import useDefault from '@/store/useDefault.js'
//данные layouts
import sidebar from '@/store/layout/sidebar'
//данные billing
import billing from '@/store/billing/billing'
import rates from '@/store/billing/rates'
import writeOff from '@/store/billing/writeOff'
//выгрузка данных 
import uploadRequest from '@/store/billing/uploadRequest'
//данные terminals
import terminalsFuel from '@/store/terminals/terminalsFuel'
import terminalsCar from '@/store/terminals/terminalsCar'
import addingSensors from '@/store/terminals/addingSensors'
//данные events
import eventsFolders from '@/store/events/eventsFolders'
//данные топливных сервисов
import fuelCardServices from '@/store/fuelCardServices/fuelCardServices'
//данные страницы геозон
import geozones from '@/store/geozones/geozones'
//данные страницы Группы
import groups from '@/store/groups/groups'
//данные страницы Запланированные отчеты
import sheduledReports from '@/store/sheduledReports/sheduledReports'
//данные страницы Роли
import roles from '@/store/roles/roles'
//данные страницы Пользователи
import users from '@/store/users/users'

export default createStore({
  modules: {
    //default
    useApi,
    useDefault,
    //layouts
    sidebar,
    //billing
    billing,
    rates,
    writeOff,
    uploadRequest,
    //terminals
    terminalsFuel,
    terminalsCar,
    addingSensors,
    //events
    eventsFolders,
    //fuelCardServices
    fuelCardServices,
    // groups page
    groups,
    // sheduledReports page
    sheduledReports,
    // roles page
    roles,
    // users page
    users,
    // geozones page
    geozones,
  }
})
