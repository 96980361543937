<template>
    <div class="modalRates">
        <div class="modalRates__button">
            <ButtonComponent @click="closeRate">Закрыть окно</ButtonComponent>
        </div>
        <div class="modalRates__rate">
            <div class="modalRates__rate-title">Тариф</div>
            <select class="modalRates__rate-select">
                <option :value="rate.id" v-for="rate in $store.state.billing.allRates.value" :key="rate.price"
                    :selected="( (dataModal && dataModal.rateId)? dataModal.rateId == rate.id : $store.state.billing.defaultRateCompany == rate.price)">
                    {{rate.price}}
                </option>
            </select>            
        </div>
        <div class="modalRates__modules">
            <div class="modalRates__modules-title">Модули</div>
            <div class="modalRates__modules-list">
                <div class="modalRates__modules-item" v-for="modul in dataModules" :key="modul.name">
                    <CheckboxComponent :data="modul" :checked="modul.checked"
                        @dataCheckbox="dataCheckboxModules"  /> 
                </div>
            </div>
        </div> 
        <div class="modalRates__button">       
            <ButtonComponent @click.stop="changeRate">Сохарнить</ButtonComponent>
        </div>
    </div>
</template>
<script>
import CheckboxComponent from "@/components/UI/CheckboxComponent.vue";
import ButtonComponent from "@/components/UI/ButtonComponent.vue";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
export default {
    name: 'modalRates',
    components: {
        CheckboxComponent,
        ButtonComponent
    },
    props:{
        dataModal:{
            type: Object,
            default: ()=>{}
        },
    },
    setup(props, {emit}){
        const store = useStore()

        const checked = ref(null)

        const dataAllRates = ref(null)
        //первый элемент в [] будет checked
        const dataModules = ref([
            {
            name: 'Главный',
            type: 'MAIN',
            checked: true
            },
            {
            name: 'Топливный',
            type: 'FUEL',
            checked: false
            }
        ])

        const closeRate = ()=>{
            emit('closeRates',false)
        }
        //выбранный модуль
        const changeModules = ref([])
        const dataCheckboxModules =(value,name)=>{
            if(value)
                changeModules.value.push(...dataModules.value.filter( modul => modul.name == name))
            else
                changeModules.value = changeModules.value.filter( modul => modul.name != name)
        }
        
        const changeRate = ()=>{
            // начальные данные для передачи
            dataAllRates.value = {
                
                folderId: store.state.billing.editTableTerminal.folderId,
                modules: changeModules.value.map(terminal => terminal.type)
            }
            // данные селекта тарифа
            let rate = document.querySelector('.modalRates__rate-select').value

            //собираем данные для передачи
            dataAllRates.value.id = props.dataModal.id
            dataAllRates.value.rateId = parseInt(rate)
            dataAllRates.value.ratePrice = (store.state.billing.allRates.value.filter(item=> item.id == rate))[0].price

            store.commit('setEditRatesTerminal',dataAllRates.value)//добавляем инфо(тарифы) о терминале в []

            // активная компания в кот произошло изменение
            document.querySelectorAll('.companyFree__item').forEach(item=>{
                item.querySelectorAll('.invoiceForm-rate__terminals .table__tbody .table__tbody-row').forEach(el=>{
                    if(el.dataset.idterminal == dataAllRates.value.id){
                        item.querySelector('.companyFree__item-name .checkbox-input').checked = true
                    }
                })
            }) 
            // поднимаем данные для отрисовки (в InvoiceComponent.vue)   
            emit('changedRates',dataAllRates.value)
        }

        onMounted(()=>{
            // при открытие окна тарифов, выставляем активные модули. Если таковых нет, высталяем "Главный" по умолчанию
            if(store.state.billing.editTableTerminal.modules.length)
                 dataModules.value.forEach( modul => modul.checked = store.state.billing.editTableTerminal.modules.includes(modul.type) )
            else
                dataModules.value.forEach(modul => modul.checked = modul.type == 'MAIN')

            // сохраняем активный модуль 
            changeModules.value = dataModules.value.filter( modul => modul.checked )
        })

        return{
            dataCheckboxModules,
            changeModules,

            checked,
            dataAllRates,
            dataModules,
            changeRate,
            closeRate
        }
    }
}
</script>
<style lang="scss" >
@import '@/assets/styles/index.scss';
.modalRates{
    width: 100%;
    max-height: 600px;
    overflow-y: auto;
    padding: 5px 10px;
    &__rate{
        padding-bottom: 30px;
        border-bottom: 1px solid $buttonColor;
        margin-bottom: 30px;
        &-title{
            @include fontStyleTitle;
        }
        &-select{
            @include select;          
        }
    }
    &__modules{
        margin-bottom: 30px;
        &-title{
            @include fontStyleTitle;
        }
        &-item{
            transition: all .2s ease-in-out;
            padding: 8px 0;
            & .checkbox-label::after{
                left: 0;
                right: auto;
            }
            & .checkbox-label{
                padding-left: 28px;  
            }
            &:hover{
                background: $rowHover;
            }
        }
    }
    &__button{
        text-align: end;
    }
}
</style>