<template>
  <form class="invoiceForm" v-if="inputDataDefault && $store.state.billing.allRates" @submit.prevent = submitCreateEdit>
    <div class="invoiceForm-inputGroup invoiceForm__number dataInput" >
      <InputComponent :data="inputDataDefault[0]" :required="true" />
    </div>
    <!-- инпуты -->
    <div class="invoiceForm-inputGroup invoiceForm__email dataInput">
      <InputComponent :data="inputDataDefault[1]" :required="true" />
    </div>
    <div class="invoiceForm-inputGroup__select dataInput">
      <div class="invoiceForm-rate">
        <div class="invoiceForm-rate__name" v-if="$store.state.billing.allRates.name && $store.state.billing.allRates.name.length">
          {{$store.state.billing.allRates.name}}
        </div>
        <select class="invoiceForm-rate__select"  @change="changeRate">
            <option v-for="rate in $store.state.billing.allRates.value" :key="rate.price"
            :value="rate.id" 
            >{{rate.price}}</option>
        </select>
      </div>
    </div>
    <div class="invoiceForm-inputGroup invoiceForm__balance dataInput" :class="{'editInvoice-active': (selectedTab && selectedTab.tab == 'edit')}">
      <InputComponent :data="inputDataDefault[2]" :required="true" :disabled="(selectedTab && selectedTab.tab == 'edit')" />
    </div>
    <!-- чек-боксы -->
    <div class="invoiceForm-inputGroup__checkbox checkbox-isBlockObjects dataInput" >
      <CheckboxComponent :data="inputDataCheckbox[0]" :visibleDots="true" />
    </div>
    <div class="invoiceForm-inputGroup__checkbox checkbox-isBlockInvoice dataInput" >
      <CheckboxComponent :data="inputDataCheckbox[1]" :visibleDots="true" />
    </div>
    <div class="invoiceForm-inputGroup__checkbox checkbox-isShowDebt dataInput" >
      <CheckboxComponent :data="inputDataCheckbox[2]" :visibleDots="true" />
    </div>
    <!-- инпут кол-ва дней -->
    <div class="invoiceForm-inputGroup invoiceForm__notice dataInput">    
      <InputComponent :data="inputDataDefault[3]" :required="true" placeholder="Дни" />      
    </div>              
    <!-- инпут порог блокировки -->
    <div class="invoiceForm-inputGroup invoiceForm__blockingLevel dataInput">    
      <InputComponent :data="inputDataDefault[4]" :required="true" />      
    </div>   
    <div class="invoiceForm__company" v-if="$store.state.billing.mainCompaniesFree && $store.state.billing.mainCompaniesFree.length">
      <div class="freeCompany">

        <div class="invoiceForm__company-header">
          Компании
        </div>
        <div class="invoiceForm__company-body affectedCompanies">
          <div class="invoiceForm__company-search">
              <SearchBilling/>
          </div>
          <div class="invoiceForm__company-table" >
            <div class="companyFree">
              <div class="companyFree__item"  v-for="company in $store.state.billing.mainCompaniesFree" :key="company.name"
                :class="{
                'companyFree__item--active':company.activeArrow
                }"
                :style="{
                height: company.activeArrow? 100+'%' : 41+'px'
                }"
                >
                <div class="companyFree__item-inner">
                <div class="companyFree__item-arrow" @click="activeCompanyFree(company)">
                     <svg v-if="$store.state.billing.mainTerminalsFree.filter(terminals=> terminals.folderId == company.id).length" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                       <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                    </svg>
                </div>
                <div class="companyFree__item-name" :data-id="company.id">
                    <CheckboxComponent :data="company" />
                </div>
                </div>
                <div class="invoiceForm-rate__terminals" v-show="company.activeArrow">
                  <div class="companyTerminals" v-if="$store.state.billing.isActiveTerminals">
  
                    <div class="companyTerminals__item-table" ref="terminals">
                        <TableInvoiced :data="$store.state.billing.mainTerminalsFree.filter(terminals=> terminals.folderId == company.id)"
                          :defaultRateId = "company.defaultRateId"
                          @editRateInvoice = "editRateInvoice" />
                    </div>     
                      
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>

      </div>
    </div>
    <div class="preloaderFreeCompanies" v-else>
        <SpinnerComponent/>
    </div>
    <div class="invoiceForm__submit">    
      <ButtonComponent class="invoiceForm__submit-btn" type="submit">
      {{(selectedTab && selectedTab.tab == 'edit') ?'Редактирование':'Создание'}}
      </ButtonComponent>   
    </div>

  </form>        

</template>
    
  <script>
    import SpinnerComponent from "@/components/UI/SpinnerComponent.vue";
    import ButtonComponent from "@/components/UI/ButtonComponent.vue";
    import TableInvoiced from "@/components/billingComponents/TableInvoiced.vue";
    import CheckboxComponent from "@/components/UI/CheckboxComponent.vue";
    import SearchBilling from "@/components/billingComponents/SearchBilling.vue";
    import InputComponent from "@/components/UI/InputComponent.vue";
  
    import { useStore } from 'vuex';
    import { ref, onMounted, onUnmounted, watchEffect } from "vue";

    export default {
      name:'CreateOrEditInvoice',
      components: {
        SpinnerComponent,
        ButtonComponent,
        InputComponent,
        CheckboxComponent,
        SearchBilling,
        TableInvoiced
      },
      props:{
          selectedTab:{
            type: Object || Boolean,
            default: false
          },
          invoiceEdit:{
            type: Object,
            default: null
          }
      },
      setup(props, {emit}){
        const store = useStore()
        store.dispatch('getCompaniesFree')
        const editRateInvoice = (terminal)=>{     
          emit('editRateInvoice', terminal)
        }

        const activeCompanyFree = (company)=>{
          company.activeArrow = !company.activeArrow
          if(company.activeArrow && props.selectedTab && props.selectedTab.tab == 'create')
            store.commit('setMainTerminalsFree',store.state.billing.createTerminalsFree)
          if(company.activeArrow && props.selectedTab && props.selectedTab.tab == 'edit')
            store.commit('setMainTerminalsFree',store.state.billing.editTerminalsFree)

        }

        const changeActions = watchEffect(()=>{
          if((props.selectedTab && props.selectedTab.tab == 'create') && store.state.billing.createCompaniesFree){
            //обновляем основные данные на отрисовку (на create)
            store.commit('setMainCompaniesFree',store.state.billing.createCompaniesFree)
            store.commit('setMainTerminalsFree',store.state.billing.createTerminalsFree)
            //обнуляем  данные тарифов , изменение которых мы вносили когда изменяли тарифы и модули терминалов
            store.commit('setClearRatesTerminal')
          }

        })
        // динамическая отрисовка при изменений тарифа (локально)
        const changeSelectRate = ref(null)
        const terminals = ref()

        const changeRate =(e)=>{
          changeSelectRate.value = store.state.billing.allRates.value.filter(rate => rate.id == e.target.value)[0].price
          // изменяем все терминалы у хоторых нет rateId на измененный defaultId 
          store.commit('setDefaultRateCompany', changeSelectRate.value)
        }

        // данные для отрисовки при первой загрузки 
        const inputDataDefault = ref(null)
        const inputDataCheckbox = ref(null)

        function allRatesload(defaultId){
          if(store.state.billing.allRates.value && store.state.billing.allRates.value.length){
            store.commit('setDefaultRateCompany', store.state.billing.allRates.value.filter(rate => rate.id == defaultId)[0].price)
          }else{
            setTimeout(()=> {
              inputLoad()
            },200)
          }     
        }

        function inputLoad(){
          if(document.querySelector('.invoiceForm-rate .invoiceForm-rate__select').value.length && store.state.billing.invoiceForEdit.defaultRateId){
            document.querySelector('.invoiceForm-rate .invoiceForm-rate__select').value = store.state.billing.invoiceForEdit.defaultRateId
            //сохраняем дефолтное значение для отрисовки тарифа у терминалов со значением null
            allRatesload(store.state.billing.invoiceForEdit.defaultRateId)
          }else{
            setTimeout(()=> {
              inputLoad()
            },300)
          }          
        }

        const submitCreateEdit = ()=>{
          //открываем дом элементы отмеченных свободных компаний
          document.querySelectorAll('.companyFree .companyFree__item').forEach(item=>{
            if(item.querySelector('.checkbox-input').checked){
              store.state.billing.mainCompaniesFree.find(el => el.id == item.querySelector('.companyFree__item-name').dataset.id).activeArrow = true
            }
          })

          // сбор данных инпутов
          const invoiceNumber = document.querySelector('.invoice__invoiceCreateOrEdit .invoiceForm__number .input-text').value
          const email = document.querySelector('.invoice__invoiceCreateOrEdit .invoiceForm__email .input-text').value
          const balance = document.querySelector('.invoice__invoiceCreateOrEdit .invoiceForm__balance .input-text').value
          const notice = document.querySelector('.invoice__invoiceCreateOrEdit .invoiceForm__notice .input-text').value
          const blockingLevel = document.querySelector('.invoice__invoiceCreateOrEdit .invoiceForm__blockingLevel .input-text').value
          // сбор данных селекта
          let rate = document.querySelector('.invoice__invoiceCreateOrEdit .invoiceForm-rate__select').value
          // сбор данных чекбоксов
          const isBlockObjects = document.querySelector('.invoice__invoiceCreateOrEdit .checkbox-isBlockObjects .checkbox-input').checked
          const isBlockInvoice = document.querySelector('.invoice__invoiceCreateOrEdit .checkbox-isBlockInvoice .checkbox-input').checked
          const isShowDebt = document.querySelector('.invoice__invoiceCreateOrEdit .checkbox-isShowDebt .checkbox-input').checked

          // сбор данных компаний в таблице
          const companiesId = []
          document.querySelectorAll('.invoice__invoiceCreateOrEdit .companyFree .checkbox-input').forEach(item=>{
            if(item.checked){
              companiesId.push(parseInt(item.parentNode.parentNode.dataset.id))                
            }
          })

          //собираем данные всех измененных в ручную терминалов
          let terminalsBillingInfo = []

          store.state.billing.mainTerminalsFree.forEach( terminal =>{
            store.state.billing.allModifiedTerminals.forEach( id =>{
              if(terminal.id == id){
                terminalsBillingInfo.push({
                  id: terminal.id,
                  rateId: terminal.rateId,
                  modules: terminal.modules
                })                
              }              
            })
          })

          // данные для передачи
          let data={
            "invoiceNumber": invoiceNumber,
            "email": email,
            "defaultRateId": parseInt(rate),
            "balance": parseInt(balance),
            "noticeBeforeBlock": parseInt(notice),
            "isBlockObjects": isBlockObjects,
            "isBlockInvoice": isBlockInvoice,
            "isShowDebt": isShowDebt,
            "companiesId": companiesId,
            "terminalsBillingInfo": terminalsBillingInfo,
            "blockingLevel": parseInt(blockingLevel)
          }

          // --действия--
          if(props.selectedTab && props.selectedTab.tab == 'create'){

            //отправка запроса с данными
            store.dispatch('createInvoices', data).then((status)=> {
              if(store.state.billing.allInvoices.length)
                store.commit('setVisibleInvoices',true)
              else{
                store.commit('setVisibleInvoices',false)
              }  
              // если нет ошибки
              if(status == 201){
                //удаление отмеченных свободных компаний из общего массива
                store.commit('setDeleteCompaniesFree', data.companiesId) 
                //обновление запросов
                store.dispatch('getCompaniesFree')
                store.dispatch('getAllInvoices')
                //редирект на гравную
                emit('redirectOnMain', store.state.billing.billingTabs[0])                  
              }
            })
          }else {
            
            // добавляем id редактируемго счета , для замены счетов
            data.id = store.state.billing.invoiceForEdit.id 
            //отправляем данные на сервер для изменения счета
            store.dispatch('changeInvoice',data).then((status)=>{  // передача данных вмсето id?
              // если нет ошибки
              if(status == 204){
                //заменяем редактируемый счет на свежий со свежими данными у себя
                store.commit('setUpdateInvoices', data)
                //обновление запросов
                store.dispatch('getCompaniesFree')
                store.dispatch('getAllInvoices')
                store.commit('setDefaultRateCompany', null)
                //редирект
                emit('redirectOnMain', store.state.billing.billingTabs[0])              
              }
            })
          }
        }        
        onMounted(()=>{
          // данные статичны, в сторе
          inputDataCheckbox.value = store.state.billing.inputDataCheckbox
          inputDataDefault.value = store.state.billing.inputDataDefault
          // делаем актывными DOM-елементы (терминалы) через setTimout чтобы измежать задержки перехода(из-за v-show)
          setTimeout(()=>{
            store.commit('setActiveTerminals',true)
          },0)


          //  ЗАГРУЗКА ДАННЫХ ПРИ РЕДАКТИРОВАНИИ
          if((props.selectedTab && props.selectedTab.tab == 'edit') && store.state.billing.editCompaniesFree){
            store.dispatch('getEditInvoice',store.state.billing.invoiceForEdit.id).then(()=>{ // получаем данные ред счета(нет инфы о терминалах)
            }) 

            //отрисовка инпутов
            setTimeout(()=>{            
              document.querySelector('.invoice__invoiceCreateOrEdit .invoiceForm__number .input-text').value = store.state.billing.invoiceForEdit.invoiceNumber
              document.querySelector('.invoice__invoiceCreateOrEdit .invoiceForm__email .input-text').value = store.state.billing.invoiceForEdit.email
              document.querySelector('.invoice__invoiceCreateOrEdit .invoiceForm__balance .input-text').value = store.state.billing.invoiceForEdit.balance
              document.querySelector('.invoice__invoiceCreateOrEdit .invoiceForm__notice .input-text').value = store.state.billing.invoiceForEdit.noticeBeforeBlock
              if(store.state.billing.invoiceForEdit.blockingLevel || store.state.billing.invoiceForEdit.blockingLevel == 0)
                document.querySelector('.invoice__invoiceCreateOrEdit .invoiceForm__blockingLevel .input-text').value = store.state.billing.invoiceForEdit.blockingLevel
              // отрисовка данных чекбоксов
              document.querySelector('.invoice__invoiceCreateOrEdit .checkbox-isBlockObjects .checkbox-input').checked = store.state.billing.invoiceForEdit.isBlockObjects
              document.querySelector('.invoice__invoiceCreateOrEdit .checkbox-isBlockInvoice .checkbox-input').checked = store.state.billing.invoiceForEdit.isBlockInvoice
              document.querySelector('.invoice__invoiceCreateOrEdit .checkbox-isShowDebt .checkbox-input').checked = store.state.billing.invoiceForEdit.isShowDebt
              // отрисовка данных селекта тарифа 
              inputLoad()
            },0)

            //запрос на обновление всех свободных счетов и терминалов
            store.dispatch('getEditTerminals',store.state.billing.invoiceForEdit.companiesId).then(()=>{
              //отрисовка редактированных компаний
              store.state.billing.invoiceForEdit.companiesId.forEach(el=>{
                document.querySelectorAll('.invoice__invoiceCreateOrEdit .companyFree .companyFree__item-name').forEach(item=>{
                  if(item.dataset.id == el)
                    item.querySelector('.checkbox-input').checked = true              
                })              
              })
            })
          }  
        })
        
        onUnmounted(()=>{
          // обновление данных, отвечающего за  добавление измененных терминалов
          store.commit('clearModifiedTerminals')    
        })  
  
        return{
          terminals,
          changeSelectRate,
          changeRate,

          editRateInvoice,
          inputLoad,
          changeActions,

          activeCompanyFree,
          submitCreateEdit,

          inputDataDefault,
          inputDataCheckbox,
        }
      }
    }
  </script>