
export default{
    state: ()=>({
        chapters: [
            {
                path: `/frame/users-main`,
                name: 'Пользователи',
                route: 'usersMain', // название роута
                title: 'НАСТРОЙКА ПОЛЬЗОВАТЕЛЕЙ',
                icon: ['people','people--active'],
                hoverIcon: false,
                isActiveSection: false,
            },
            {
                path: `/frame/handbook`,
                name: 'Справочник',
                route: 'handbook', // название роута
                title: 'НАСТРОЙКА СПРАВОЧНИКА',
                icon: ['journal-medical','journal-medical--active'],
                hoverIcon: false,
                isActiveSection: false,
            },
            {
                path: `/frame/groups`,
                name: 'Группы',
                route: 'groups', // название роута
                title: 'НАСТРОЙКА ГРУПП',
                icon: ['wallet2','wallet2--active'],
                hoverIcon: false,
                isActiveSection: false,
            },
            {
                path: `/frame/terminals/fuel`,
                name: 'Терминалы',
                route: 'terminals', // название роута
                title: 'НАСТРОЙКА ТЕРМИНАЛОВ',
                icon: ['terminal','terminal--active'],
                hoverIcon: false,
                isActiveSection: false,
            },
            {
                path: `/frame/terminal-points`,
                name: 'Сырые данные',
                route: 'terminalPoints', // название роута
                title: 'СЫРЫЕ ДАННЫЕ',
                icon: ['text-indent-left','text-indent-left--active'],
                hoverIcon: false,
                isActiveSection: false,
                pathLocal: `/frame/terminal-points`
            },
            {
                path: `/frame/scheduledReports`,
                name: 'Запланированные отчеты',
                route: 'scheduledReports', // название роута
                title: 'НАСТРОЙКА ОТЧЕТОВ',
                icon: ['gear','gear--active'],
                hoverIcon: false,
                isActiveSection: false,
            },
            {
                path: `/frame/billing`,
                name: 'Система биллинга',
                route: 'billing', // название роута
                title: 'НАСТРОЙКА ПЛАТЕЖЕЙ',
                icon: ['cash-stack','cash-stack--active'],
                hoverIcon: false,
                isActiveSection: false,
            },
            {
                path: `/frame/events`,
                name: 'События',
                route: 'events', // название роута
                title: 'НАСТРОЙКА СОБЫТИЙ',
                icon: ['events','events--active'],
                hoverIcon: false,
                isActiveSection: false,
            },
            {
                path: `/frame/fuelCardServices`,
                name: 'Сервисы топливных карт',
                route: 'fuelCardServices', // название роута
                title: 'НАСТРОЙКА СЕРВИСОВ ТОПЛИВНЫХ КАРТ',
                icon: ['cloud','cloud--active'],
                hoverIcon: false,
                isActiveSection: false,
            },
            {
                path: `/frame/geozones`,
                name: 'ГЕОЗОНЫ',
                route: 'geozones', // название роута
                title: 'НАСТРОЙКА ГЕОЗОН',
                icon: ['geozones','geozones--active'],
                hoverIcon: false,
                isActiveSection: false,
            },
            {
                path: `/frame/roles`,
                name: 'Роли',
                route: 'roles', // название роута
                title: 'НАСТРОЙКА РОЛЕЙ',
                icon: ['roles','roles--active'],
                hoverIcon: false,
                isActiveSection: false,
            },
        ]
    }),
    mutations:{
        // активный раздел меню
        setActiveSection(state, routeName){
            state.chapters.forEach( item => (item.route == routeName)? item.isActiveSection = true : item.isActiveSection = false)
        },
    }
}