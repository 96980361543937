<template>
    <ul class="tabs">
        <li class="tabs__item" v-for="(item,idx) in data" :key="idx"
            :class="{
                'tabs__item--active': item.active
            }"
            @click="activeTab(item)">
            {{item.tab}}
        </li>
    </ul>
</template>
  
<script>

  export default {
    components: {
    },
    props:{
        data: {
            type: Array,
            default: () => []
        }
    },
    setup(props, {emit}){

        const activeTab = (tab)=>{
            emit('allInvoiced', tab)
        }
        return{
            activeTab
        }
    }
  }
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.tabs{
    font-size: 0.9em;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    &__item{
        border-radius: 3px;
        padding: 5px 10px;
        border-bottom: 2px solid transparent;
        transition: all .2s ease-in-out;
        cursor: pointer;
        &--active{
            border-bottom: 2px solid $hoverItem;
        }
        &:hover{
            background-color: $backgroundMain;
        }
    }
}
</style>
  