import { useApi } from "@/composition/useApi.js"
export default{
    state: ()=>({
        titleForHeader: null,
        allFolders: [],

        terminalsGroups: null,
    }),
    mutations:{
        // для вывода навания страницы
        setTitleForHeader(state, data){
            state.titleForHeader = data
        },
        // получение папок
        setAllFolders(state,data){
            state.allFolders = data
        },

        // типы терминалов
        setTerminalsGroups(state,data){
            data.forEach((itm, idx) => itm.isSelected = idx === 0)
            state.terminalsGroups = data
        },
        setSelectedTerminalGroup(state, num){
            state.terminalsGroups.forEach(itm => {
                itm.isSelected = itm.id === num ? true : false
            })
        }
    },
    actions:{
        // получение всех папок
        getAllFolders({commit}){
            const { api } = useApi();
            return api.get(`/folders`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setAllFolders',r.data)   
                })
        }, 
        // получение групп всех терминалов
        getTerminalsGroups({commit}){
            const { api } = useApi();
            return api.get(`/terminals/groups`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setTerminalsGroups', r.data)
                })
        },
        // получить карту по id
        getCardsId(state, data){
            const { api } = useApi();
            return api.get(`/cards/${data.id}`)
                .then((r)=>{
                    if(r.status == 200){
                        if(data.info == 'cardId')
                            return r.data
                        if(data.info == 'cardGroupIds')
                            return r.data              
                    }
                })
        },

        // получить группу карт по id
        getGroupCard(state, id){
            const { api } = useApi();
            return api.get(`/cards/groups/${id}`)
                .then((r) => {
                    if(r.status == 200)
                        return r.data
                })
        },

        // стоп/пауза терминалов
        getTerminalPause( state, teminal){
            const { api } = useApi();
            return api.patch(`/terminals/${teminal.id}/status`, { isEnabled: teminal.isEnabled } )
                .then((r)=>{
                    if(r.status == 200)
                        return r
                })
        },
        getTerminalRun( state, teminal){
            const { api } = useApi();
            return api.patch(`/terminals/${teminal.id}/status`, { isEnabled: teminal.isEnabled } )
                .then((r)=>{
                    if(r.status == 200)
                        return r
                })
        },
        // удаление терминала
        delitingTerminal(state, data){
            const { api } = useApi();
            return api.delete(`/terminals/${data.id}`)
                .then((r) => {
                    if(r.status == 204)
                        return r.status
                })
        }, 
    }
}