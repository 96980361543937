<template>
  <div class="invoice">
    <h1 class="invoice__info">{{(!selectedTab || (selectedTab && selectedTab.tab == 'Все счета')) ? 'Все счета' : (selectedTab && selectedTab.tab == 'edit')?'Редактирование счета':'Создание счета'}}</h1>
    <div class="invoice__invoiceList" v-if="!selectedTab ||(selectedTab && selectedTab.tab == 'Все счета')">
      <div class="invoice__invoiceList-companies" v-if="isVisibleAllInvoices">
        <TableAllInvoices :edit = "false" v-if="$store.state.billing.allInvoices && $store.state.billing.allInvoices.length && $store.state.billing.allRates"
          @companyEdit="companyEdit"
          @companyDelete = "companyDelete" 
          @openModalRefill="openModalRefill"
            />
        <div class="invoice__invoiceList-noCompanies" v-else>
          <SpinnerComponent/>
        </div>
      </div>
      <div class="invoice__invoiceList-noCompanies" v-else>
        {{ $store.state.billing.isEmptyRates? 'Нет активных тарифов. ': null}} Счета отсутствуют
      </div>
    </div>
    <div class="invoice__invoiceCreateOrEdit" v-if="(selectedTab && selectedTab.tab == 'create') || (selectedTab && selectedTab.tab == 'edit')">
            
      <CreateOrEditInvoice :selectedTab="selectedTab"
        @editRateInvoice="editRateInvoice"
        @redirectOnMain="redirectOnMain"  />
      <div class="invoice__invoiceCreateOrEdit-modalError" v-if="$store.state.billing.isVisibleErrorCreate">  
        <ModalError  :infoText="$store.state.billing.textErrorCreate" />
      </div>
    </div>
     <!-- редактирование конкретного тарифа и модуля у терминла -->
    <div class="invoice__modal-rates" v-if="isRatesModal">
      <ModalDefaultStyles v-if="$store.state.billing.allRates && $store.state.billing.allRates.value" :width=550>
        <ModalRatesInvoices :dataModal="dataTerminalActive" @closeRates="closeRates" @changedRates="changedRates"/>
      </ModalDefaultStyles>          
    </div>
    <div class="writeOff__modalRefill" v-if="isVisibleRefill">
        <ModalDefaultStyles>
              <ModalRefill :data="dataRefill" @closeModalRefill="closeModalRefill" />
        </ModalDefaultStyles>
    </div>
  </div>
</template>
  
<script>
  import SpinnerComponent from "@/components/UI/SpinnerComponent.vue";
  import TableAllInvoices from "@/components/billingComponents/TableAllInvoices.vue";
  import CreateOrEditInvoice from "@/components/billingComponents/CreateOrEditInvoice.vue";
  import ModalError from "@/components/modalComponents/ModalError.vue";
  import ModalDefaultStyles from "@/components/UI/ModalDefaultStyles.vue";
  import ModalRefill from "@/components/modalComponents/billing/ModalRefill.vue";
  import ModalRatesInvoices from "@/components/modalComponents/billing/ModalRatesInvoices.vue";
  import { useStore } from 'vuex';
  import { ref, onMounted,watchEffect } from "vue";
  export default {
    name: 'invoiceComponent',
    components: {
      ModalDefaultStyles,
      ModalRatesInvoices,
      ModalRefill,
      CreateOrEditInvoice,
      ModalError,
      TableAllInvoices,
      SpinnerComponent,
    },
    props:{
      selectedTab:{
        type: Object || Boolean,
        default: false
      }
    },
    setup(props, {emit}){
      const store = useStore()
      const isVisibleAllInvoices = ref(true)
      const redirectOnMain = (tab)=>{
        isVisibleAllInvoices.value = store.state.billing.isVisibleInvoices
        emit('redirectCreate', tab)
      }

      const companyEdit = (tab,company)=>{
        //запоминаем счет для отправки на редактирование id
        store.commit('setInvoiceForEdit',company)

        // переход на страницу
        emit('editInvoice',tab, company)
      }

      const companyDelete = (invoice)=>{
        if(!confirm('Удалить счет?'))
          return
        
        //удаление локально
        store.commit('deleteInvoices',invoice.id)
        //условие отображение главной страницы
        store.commit('setVisibleInvoices',true)

        store.dispatch('getDeleteInvoice',invoice.id).then(()=>{
          if(store.state.billing.allInvoices.length){
            //обновление всех локальных данных для отрисовки (при создании)
            store.commit('clearCompaniesFree')
            store.commit('clearCreateCompaniesFree')
            //запрос на получение новых данных (где будут удаленные комипнии)
            store.dispatch('getCompaniesFree')
          }else
            store.commit('setVisibleInvoices',false)
            isVisibleAllInvoices.value = store.state.billing.isVisibleInvoices
            store.dispatch('getAllInvoices')
        })

        // редирект
        isVisibleAllInvoices.value = store.state.billing.isVisibleInvoices
        emit('redirectCreate', store.state.billing.billingTabs[0])
      }

      const changeActions = watchEffect(()=>{
        if((props.selectedTab && props.selectedTab.tab == 'Все счета') && store.state.billing.mainCompaniesFree){
          // закрытие таблицы во вкладке "отчет списания" 
          store.commit('setVisibleTable',false)
          // обнуление данных для отрисовки компаний в создании/редактировании
          store.commit('clearCompaniesFree')
          //закрываем DOM-элементы (терминалы), чтобы в след раз не ждать их отрисовки(не было задержки)
          store.commit('setActiveTerminals',false)
        }   
      })

      function fetchAllRequestBilling(){
        if(localStorage.getItem('tokenCSRF')){
          store.dispatch('getAllInvoices').then(()=> {isVisibleAllInvoices.value = store.state.billing.isVisibleInvoices})
          store.dispatch('getAllRates')  
          store.dispatch('getAllModules')    
        }else{
          setTimeout(()=>{
            fetchAllRequestBilling()
          },300)
        }
      }

      onMounted(()=>{
        fetchAllRequestBilling()
      })

      // модальные окна
      const isRatesModal = ref(false)
      const dataTerminalActive = ref(null)

      const closeRates = (bool)=>{
        store.commit('setEditTableTerminal', null) 
        isRatesModal.value = bool
      }

      const editRateInvoice = (terminal)=>{   
        // редактируемый терминал 
        store.commit('setEditTableTerminal', terminal)  
        //передача данных в мод окно + появление окна
        dataTerminalActive.value = terminal
        isRatesModal.value = true
      }

      // отрисовываем изменения при редактировании тарифов, а также изменяем данные в теге
      const changedRates = (terminalRates)=>{
        store.state.billing.mainTerminalsFree.forEach( terminal => {
          if(terminal.id == terminalRates.id){
            terminal.modules = terminalRates.modules
            terminal.rateId = terminalRates.rateId
          }
        })
        document.querySelectorAll('.companyTerminals .table__tbody-row').forEach(item=>{
          if(item.dataset.idterminal == terminalRates.id){
            item.querySelector('.table__visible').style.fontWeight = 700
            item.querySelector('.table__visible').style.color = 'green'
          }
        })

        // добавление id-шников измененных терминалов 
        store.commit('addingModifiedTerminals', terminalRates.id)

        isRatesModal.value = false
      }
      // открыите окна "пополнение счета"
      const isVisibleRefill = ref(false)
      const dataRefill = ref(null)

      const openModalRefill = (data)=>{
        dataRefill.value = data
        isVisibleRefill.value = true
      }
      const closeModalRefill = (bool)=>{ isVisibleRefill.value = bool }

      return{
        fetchAllRequestBilling,
        isVisibleAllInvoices,
        redirectOnMain,
        changeActions,
        companyEdit,
        companyDelete,
        //модальные окна
        isRatesModal,
        dataTerminalActive,
        closeRates,
        editRateInvoice,
        changedRates,
        
        openModalRefill,
        closeModalRefill,
        dataRefill,
        isVisibleRefill
      }
    }
  }
  </script>
  <style lang="scss" src="@/assets/styles/billing/invoice.scss"></style>
  