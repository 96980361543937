<template>
    <div class="search">
        <input  class="search__input" type="text" placeholder="Поиск по терминалам" v-model="inputValue" @input="searchBy">
        <div class="search__icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
        </div>
    </div>
</template>
<script>
  import { ref, onMounted} from "vue";
  import { useStore } from "vuex";
  export default {
    components: {
    },
    props:{
        data: {
            type: Object,
            default: () => {}
        }
    },
    setup(){
        const store = useStore()

        const inputValue = ref('')
        const domElements = []
        onMounted(()=>{
            domElements.value = document.querySelectorAll('.companyFree__item-name')
        })
        const searchBy = (event)=>{
            if(inputValue.value && inputValue.value.length){
                store.commit('setSearchActiveArrow',true)
                document.querySelectorAll('.companyTerminals .table__tbody .table__tbody-row').forEach(item=>{
                    if(item.querySelector('.table__name').innerText.toLowerCase().includes(event.target.value.toLowerCase())){
                        item.style.display = 'table-row'
                    }else
                        item.style.display = 'none'
                })
                document.querySelectorAll('.invoiceForm-rate__terminals').forEach(item=>{
                    if([...item.querySelectorAll('.table__tbody .table__tbody-row')].every(el=> el.style.display == 'none'))
                        item.parentNode.style.display = 'none'
                    else
                        item.parentNode.style.display = 'block'
                })                    
            }else{
                //возвращаем в исходное положение
                domElements.value.forEach(item=>{
                    item.parentNode.parentNode.querySelectorAll('.table__tbody-row').forEach(item=> item.style.display = 'table-row')
                })
                document.querySelectorAll('.invoiceForm-rate__terminals').forEach(item=> item.parentNode.style.display = 'block')  
                //закрываем все счета(видимость терминалов)
                store.commit('setSearchActiveArrow',false)
            }
        }
        return{
            domElements,
            inputValue,
            searchBy
        }
    }
  }
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.search{
    @include search;
    &--visible{
        display: none;
    }

}

</style>